import React, { type FC, useCallback, useRef } from 'react'
import Webcam from 'react-webcam'
import { Modal } from 'antd'
import CaptureVehicleComponent from "./CaptureVehicleComponent";
import AddVehicleTermsComponent from "./AddVehicleTermsComponent";
import AddVehicleInReviewComponent from "./AddVehicleInReviewComponent";
import { User } from '../models/User';

interface AddVehicleModalProps {
    visible: boolean
    setVisible: (visible: boolean) => void
    onComplete: () => void
    user: User
    captured: any;
    setCaptured: any;
    termsAcknowledged: any;
    setTermsAcknowledged: any;
    onError: (description: string, title?: string) => void
}

const AddVehicleModal: FC<AddVehicleModalProps> = (props) => {
    const webcamRef = useRef<Webcam | null>(null)

    const handleCancel = useCallback((): void => {
        // When the modal closes, stop the webcam stream
        const videoElem = webcamRef?.current?.video

        if (videoElem?.srcObject != null) {
            const stream = videoElem.srcObject as MediaStream
            const tracks = stream.getTracks()

            tracks.forEach((track) => {
                track.stop()
            })
        }
        props.setVisible(false);
        props.onComplete()
    }, [])


    const handleCompletion = useCallback((): void => {
        handleCancel()
    }, [])

    const captureComplete = () => {
        props.setCaptured(true);
    }

    const termsComplete = () => {
        props.setTermsAcknowledged(true);
    }

    const done = () => {
        console.log(`Done with captured (${props.captured}) and termsAcknowledged (${props.termsAcknowledged})`)
        handleCompletion()
    }



    return (
        <Modal
            open={props.visible}
            onCancel={handleCancel}
            footer={null}
            style={{ display: 'flex', flexDirection: 'column', top: 0, marginTop: '10px' }}
            bodyStyle={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
            {!props.captured &&
                <CaptureVehicleComponent user={props.user} onError={props.onError} onComplete={() => captureComplete()} />
            }
            {props.captured && !props.termsAcknowledged &&
                <AddVehicleTermsComponent user={props.user} onComplete={() => termsComplete()} />
            }
            {props.captured && props.termsAcknowledged &&
                <AddVehicleInReviewComponent user={props.user} onComplete={() => done()} />
            }
        </Modal>

    )
}

export default AddVehicleModal

import React, {type FC} from 'react'
import {Button, Col, Row, Typography} from 'antd'
import {useNavigate} from 'react-router-dom'
import {User} from '../models/User'
import completed_image from "../assets/completed_image.svg";

interface IntroScreenProps {
    user: User
    onError: (description: string, title?: string) => void
}


const IntroScreen: FC<IntroScreenProps> = (props) => {
    const navigate = useNavigate();

    const goToHome = () => {
        navigate('/')
    }

    return <div style={{textAlign: 'left', height: '100%', display: 'flex', justifyContent: 'center'}}>
        <Col xs={24} sm={20} md={18} lg={12} style={{display: 'flex', flexDirection: 'column'}}>
            <Row>
                <Typography style={{fontSize: 18, fontWeight: 600}}>Getting Started</Typography>
            </Row>
            <Row style={{flex: 1, height: '100%', paddingTop: '40px'}}>
                <Col span={24} style={{display: 'flex', flexDirection: 'column', height: '100%'}}>
                    <Row style={{flex: '0 0 40%', maxHeight: '40%', overflow: 'auto', justifyContent: 'center'}}>
                        <Col xs={24} sm={18} md={12} lg={8} style={{display: 'flex', justifyContent: 'center', width: '100%', height: '100%'}}>
                            <img src={completed_image} alt="Completed" style={{height: '100%', width: 'auto'}}/>
                        </Col>
                    </Row>
                    <Row style={{overflow: 'auto', paddingTop: '40px'}}>
                        <Col span={24}>
                            <Row style={{}}>
                                <Typography style={{fontSize: 18, fontWeight: 600}}>You're All Set!</Typography>
                            </Row>
                            <Row style={{marginTop: '20px'}}>
                                <Typography>
                                    You can now pay with YewPay and earn rewards at participating merchants! At the counter or payment window, just tell the cashier you're paying with YewPay. That's it!
                                </Typography>
                            </Row>
                        </Col>
                    </Row>
                    <Row style={{flex: '0 0 20%', maxHeight: '20%', overflow: 'auto', paddingTop: '40px'}}>
                        <Col span={24}>
                            <Row style={{}}>
                                <Button block type="primary" style={{fontWeight: 600, height: 40}} onClick={goToHome}>
                                    Find YewPay Merchants
                                </Button>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Col>
    </div>
}
export default IntroScreen;

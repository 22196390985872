interface dateInfoType {
  month: string
  monthNum: string
  day: string
  dayNum: number
  year: string
  yearShort: string
  hours: string
  minutes: string
  seconds: string
  ampm: string
}
const getDateInfo = (millis: number): dateInfoType => {
  const date = new Date(millis)

  const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
    'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
  ]

  const getOrdinal = (n: number) => {
    const s = ['th', 'st', 'nd', 'rd']
    const v = n % 100
    return n + (s[(v - 20) % 10] || s[v] || s[0])
  }

  const month = monthNames[date.getMonth()]
  const monthNum = String(date.getMonth()+1).padStart(2, '0').slice(-2)
  const day = getOrdinal(date.getDate())
  const dayNum = date.getDate()
  const year = String(date.getFullYear())
  const yearShort = String(date.getFullYear() % 1000)
  const hours = String(date.getHours() > 12 ? date.getHours() - 12 : date.getHours())
  const minutes = ('0' + date.getMinutes()).slice(-2)
  const seconds = ('0' + date.getSeconds()).slice(-2)
  const ampm = date.getHours() >= 12 ? 'PM' : 'AM'

  return {
    month,
    monthNum,
    day,
    dayNum,
    year,
    yearShort,
    hours,
    minutes,
    seconds,
    ampm
  }
}
export function formatDateTime (millis: number) {
  const { month, day, year, hours, minutes, seconds, ampm } = getDateInfo(millis)
  return `${month} ${day}, ${year} ${hours}:${minutes}:${seconds}${ampm}`
}

export function formatDateTimeShort (millis: number) {
  const { monthNum, dayNum, yearShort, hours, minutes, seconds, ampm } = getDateInfo(millis)
  return `${monthNum}/${dayNum}/${yearShort} ${hours}:${minutes}:${seconds}${ampm}`
}

export function formatDate (millis: number, showYear: boolean = true) {
  const { month, day, year } = getDateInfo(millis)
  if (!showYear) {
    return `${month} ${day}`
  }
  return `${month} ${day}, ${year}`
}


export function isDateFuture(millis: number) {
  // Get today's date with time set to 00:00:00
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  // Create a date object from the input milliseconds
  const inputDate = new Date(millis);
  inputDate.setHours(0, 0, 0, 0);

  // Compare the input date with today's date
  return inputDate > today;
}
import React, {type FC} from 'react'
import {Col, Row, Typography} from 'antd'
import {formatDate} from '../utils/DateUtils'
import {User} from '../models/User'
import {Mechanism} from '../models/MechanismTypes'

interface FaceSummaryProps {
  user: User
  faceInfo: Mechanism | undefined
  onClick: any
}

const FaceSummary: FC<FaceSummaryProps> = (props) => {
  return (
      <div>
        <Row onClick={() => props.onClick(props.faceInfo)}>
          <Col xs={14}>
            {(!props.faceInfo?.date_last_used && props.faceInfo?.date_created) &&
                <Typography style={{ textAlign: 'left' }}>Added {formatDate(+props.faceInfo?.date_created)}</Typography>}
          </Col>
          <Col xs={10} style={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
            <Typography style={{ textAlign: 'right', color: props.faceInfo?.status == 'ACTIVE' ? 'green' : 'red' }}>{props.faceInfo?.status}</Typography>
          </Col>
        </Row>
      </div>

  )
}

export default FaceSummary

import GeneralService from './GeneralService'
import {Component} from 'react'


export class LocationService extends Component {
  public static getLocations(northeast: any, southwest: any, accessToken: any, loadingHandler: any) {
    return GeneralService.get(`/locations/${northeast.lat()}/${northeast.lng()}/${southwest.lat()}/${southwest.lng()}`, accessToken, loadingHandler, JSON, GeneralService.USER_ENDPOINT)
  }

  public static getLocation(location_id: any, accessToken: any, loadingHandler: any) {
    return GeneralService.get(`/locations/${location_id}`, accessToken, loadingHandler, JSON, GeneralService.USER_ENDPOINT)
  }

  public static getLocationDetails(place_id: any, accessToken: any, loadingHandler: any) {
    return GeneralService.get(`/locations/details/${place_id}`, accessToken, loadingHandler, JSON, GeneralService.USER_ENDPOINT)
  }
}
import React, { type FC, useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Card, Col, Row } from 'antd'
import { type Mechanism } from '../models/MechanismTypes'
import VehicleList from './VehicleList'
import { MechanismsService } from "../services/MechanismsService";
import { User } from '../models/User'
import { EnrollVehicle } from '../models/EnrollVehicle'

interface VehiclesScreenProps {
  user: User
  onError: (description: string, title?: string) => void
}

const VehiclesScreen: FC<VehiclesScreenProps> = (props) => {
  const [vehicles, setVehicles] = useState<Mechanism[]>([])

  const [face, setFace] = useState<any>([])
  const [loading, setLoading] = useState<boolean>(true)
  const [enrolledVehicles, setEnrolledVehicles] = useState<EnrollVehicle[]>([])

  const getVehicles = useCallback(() => {
    MechanismsService.getVehicles(0, 5, props.user.access_token, setLoading).subscribe((data: Mechanism[]) => {
      setVehicles(data);
    }, (error: any) => {
      console.error(`Error fetching vehicles: ${error}`);
      props.onError(error)
    })
    MechanismsService.getEnrollmentPendingVehicles(0, 5, props.user.access_token, setLoading).subscribe((data: EnrollVehicle[]) => {
      setEnrolledVehicles(data)
    }, (error: any) => {
      console.error(`Error fetching enrollment pending vehicles: ${error}`);
      props.onError(error)
    })
  }, [props.user])
  useEffect(() => {
    getVehicles()
  }, [props.user])
  const navigate = useNavigate()
  const goToVehicle = (mechanism: Mechanism): void => {
    navigate(`${mechanism.mechanism_id}`)
  }
  const goToAddVehicle = (): void => {
    navigate(`new`)
  }
  const onAccept = useCallback((mechanism: Mechanism) => {
    mechanism.status = 'ACTIVE'
    MechanismsService.updateStatus(mechanism, props.user.access_token, setLoading).subscribe((data: any) => {
      getVehicles()
    }, (error: any) => {
      console.error(`Error activating vehicle: ${error}`);
      props.onError(error, 'Error Activating Vehicle')
    })
  }, [props.user])
  const onDecline = useCallback((mechanism: Mechanism) => {
    mechanism.status = 'DELETED';
    MechanismsService.deleteVehicle(mechanism, props.user.access_token, setLoading).subscribe((data: any) => {
      getVehicles()
    }, (error: any) => {
      console.error(`Error removing vehicle: ${error}`);
      props.onError(error, 'Error Removing Vehicle')
    })
  }, [props.user])
  return (
    <div style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', overflow: 'auto' }}>
      <Row style={{ flex: 1, marginTop: '10px', overflowY: 'auto' }}>
        <Col span={24} style={{ width: '100%', height: '100%', overflowY: 'auto' }}>
          <Card style={{ height: '100%', overflowY: 'auto', textAlign: 'left' }} title={'Vehicles'}
            extra={<a onClick={goToAddVehicle}>Add New</a>} loading={loading}>
            <VehicleList onClick={goToVehicle} vehicleList={vehicles} enrolledVehicleList={enrolledVehicles} user={props.user} onAccept={onAccept} onDecline={onDecline} />
          </Card>
        </Col>
      </Row>
    </div>
  )
}
export default VehiclesScreen

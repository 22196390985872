import React, {type FC, useEffect, useState} from 'react'
import {Button, Col, Dropdown, Input, MenuProps, Popconfirm, Row, Skeleton, Space, Typography} from 'antd'
import {useNavigate, useParams} from 'react-router-dom'
import {CheckOutlined, CloseOutlined, MoreOutlined, QuestionCircleOutlined} from '@ant-design/icons'
import ActivitySummaryComponent from '../home/ActivitySummaryComponent'
import {MechanismsService} from "../services/MechanismsService";
import {Mechanism, VehicleMeta} from '../models/MechanismTypes'
import {User} from '../models/User'
import MechanismPaymentMethodComponent from "./MechanismPaymentMethodComponent";

interface VehicleDetailScreenProps {
  user: User
  onError: (description: string, title?: string) => void
}

const VehicleDetailScreen: FC<VehicleDetailScreenProps> = (props) => {
  const navigate = useNavigate()
  const [mechanism, setMechanism] = useState<any>();
  const { id } = useParams()
  const [loading, setLoading] = useState<boolean>(true)
  const [imageLoading, setImageLoading] = useState<boolean>(true)
  const [isEditingNickname, setIsEditingNickname] = useState<boolean>(false);
  const [nicknameInput, setNicknameInput] = useState<string>(mechanism?.nickname || '');
  const items: MenuProps['items'] = [
    {
      key: '1',
      label: (
        <a onClick={() => { editNickname() }} rel={'noopener noreferrer'} >
          Edit Nickname
        </a>
      ),
    },
    {
      key: '2',
      label: (
        <a onClick={() => { changeStatus() }} rel={'noopener noreferrer'} >
          {mechanism?.status == 'DISABLED' ? 'Activate' : 'Disable'}
        </a>
      ),
    },
    {
      key: '3',
      label: (
        <Popconfirm
          title="Remove Vehicle"
          description="Are you sure you want to remove this vehicle?"
          icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
          placement={'bottom'}
          onConfirm={() => { remove() }}
          okText={'Remove'}
        >
          <a rel={'noopener noreferrer'} >
            Remove
          </a>
        </Popconfirm>
      ),
    },
    {
      key: '4',
      label: (
          <a onClick={() => {}} rel={'noopener noreferrer'} >
            Enrollment Details
          </a>
      ),
    }
  ]
  const editNickname = () => {
    setNicknameInput(mechanism?.nickname || '');
    setIsEditingNickname(true);
  }

  const editNicknameComplete = () => {
    mechanism!.nickname = nicknameInput;
    MechanismsService.renameMechanism(JSON.stringify(mechanism), props.user.access_token, setLoading).subscribe((data: any) => {
      setMechanism(data);
      setIsEditingNickname(false);
    }, (error: any) => {
      console.error(`Error updating vehicle nickname: ${error}`);
      props.onError(error, 'Error Updating Vehicle')
      setIsEditingNickname(false);
    });
  }

  const changeStatus = () => {
    let toStatus: string = mechanism?.status == 'DISABLED' ? 'ACTIVE' : 'DISABLED';
    mechanism!.status = toStatus;
    MechanismsService.updateStatus(JSON.stringify(mechanism), props.user.access_token, setLoading).subscribe((data: any) => {
      setMechanism(data)
    }, (error: any) => {
      console.error(`Error updating vehicle status: ${error}`);
      props.onError(error, 'Error Updating Vehicle')
    });
  }
  const accept = () => {
    mechanism!.status = 'ACTIVE';
    MechanismsService.updateStatus(JSON.stringify(mechanism), props.user.access_token, setLoading).subscribe((data: any) => {
      setMechanism(data)
    }, (error: any) => {
      console.error(`Error activating vehicle: ${error}`);
      props.onError('Error Activating Vehicle', error)
    });
  }

  const remove = () => {
    mechanism!.status = 'DELETED';
    MechanismsService.deleteVehicle(JSON.stringify(mechanism!.meta), props.user.access_token, setLoading).subscribe((data: any) => {
      navigate('/identifiers/vehicles')
    }, (error: any) => {
      console.error(`Error deleting vehicle: ${error}`);
      props.onError(error, 'Error Deleting Vehicle')
    });
  }

  useEffect(() => {
    MechanismsService.getVehicleById(id, props.user.access_token, setLoading).subscribe((data: Mechanism) => {
      setMechanism(data)
    }, (error: any) => {
      console.error(`Error fetching vehicle: ${error}`);
      props.onError(error, 'Error Fetching Vehicle')
    })
  }, [id, props.user])
  const meta: VehicleMeta | undefined = mechanism?.meta as VehicleMeta
  return (
      <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center'}}>
        <Col xs={24} sm={22} md={18} lg={12} style={{display: 'flex', flexDirection: 'column'}}>
          <Row>
            <Col span={20} style={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
              {isEditingNickname ? (
                <Space.Compact>
                  <Input
                    value={nicknameInput}
                    onChange={(e) => setNicknameInput(e.target.value)}
                  />
                  <Button type={'primary'} onClick={() => editNicknameComplete()}>Done</Button>
                </Space.Compact>
              ) : (
                <Typography style={{ color: 'black', fontWeight: '600', fontSize: '18px' }}>
                  {mechanism?.nickname}
                </Typography>
              )}
            </Col>
            <Col span={4} style={{ display: 'flex', flexDirection: 'column', alignItems: 'end' }}>

              <Dropdown menu={{ items }} >
                <MoreOutlined style={{ fontSize: 20 }} />
              </Dropdown>

            </Col>
          </Row>
          <Row style={{ marginTop: 20 }}>
            <Col span={12} style={{ alignItems: 'center', paddingLeft: '10px', paddingRight: '10px' }}>
              {imageLoading && <Skeleton.Image style={{ borderRadius: '8px' }} active={true} />}
              <img hidden={imageLoading} onLoad={() => { setImageLoading(false) }} src={meta?.img} style={{ width: '100%', borderRadius: '8px' }} />
            </Col>
            <Col span={12}>
              <Row>
                <Typography style={{ color: 'black', fontSize: '12px' }}>
                  <b>License Plate:</b> {meta?.plate}
                </Typography>
              </Row>
              <Row>
                <Typography style={{ color: 'black', fontSize: '12px' }}>
                  <b>State:</b> {meta?.region}
                </Typography>
              </Row>
              <Row>
                <Typography style={{ color: 'black', fontSize: '12px' }}>
                  <b>Body Type:</b> {meta?.vehicle_type}
                </Typography>
              </Row>
              {/*<Row>*/}
              {/*  <Typography style={{ color: 'black', fontSize: '12px' }}>*/}
              {/*    <b>Last Used:</b> {mechanism?.date_last_used ? formatDate(mechanism?.date_last_used) : 'N/A'}*/}
              {/*  </Typography>*/}
              {/*</Row>*/}
              <Row>
                <Typography style={{ color: 'black', fontSize: '12px' }}>
                  <b>Status:</b> {mechanism?.status}
                </Typography>
              </Row>
            </Col>
          </Row>
          {
            mechanism?.status == 'PENDING' &&
            <Row style={{ marginTop: 10 }}>
              <Col span={24}>
              <Typography style={{ color: 'black', fontWeight: '600', fontSize: '16px' }}>Confirm Vehicle</Typography>
              </Col>
                <Col span={12} >
                  <Button block onClick={accept} icon={<CheckOutlined />} style={{ color: 'green', borderColor: 'green' }} />
                </Col>
                <Col span={12} >
                  <Button block onClick={remove} icon={< CloseOutlined />} style={{ color: 'red', borderColor: 'red', marginLeft: 5 }} />
                </Col>
            </Row>
          }
          <Row style={{marginTop: '10px'}}>
            <MechanismPaymentMethodComponent user={props.user} onError={props.onError} mechanism={mechanism} newCardRedirectRoute={`/identifiers/vehicles/${mechanism?.meta?.id}`}/>
          </Row>
          <Row style={{ marginTop: '10px' }}>
            <Col span={24} style={{ width: '100%', height: '100%' }}>
              <ActivitySummaryComponent user={props.user} onError={props.onError} mechanism={mechanism} />
            </Col>
          </Row>
        </Col>
    </div >
  )
}
export default VehicleDetailScreen

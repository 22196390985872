import React, {type FC, useEffect, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import {Button, Card, Col, Row, Typography} from "antd";
import AddVehicleModal from "./AddVehicleModal";
import {User} from '../models/User';

interface AddVehicleScreenProps {
    user: User
    onError: (description: string, title?: string) => void
}

const AddVehicleScreen: FC<AddVehicleScreenProps> = (props) => {
    const navigate = useNavigate();
    const [addVehicleModalVisible, setAddVehicleModalVisible] = useState<boolean>(false);
    const [captureComplete, setCaptureComplete] = useState(false)
    const [termsAcknowledged, setTermsAcknowledged] = useState(false)


    const showAddVehicleModal = (): void => {
        setAddVehicleModalVisible(true)
    }

    const addVehicleComplete = () => {
        console.log(`AddVehicle complete with success: ${captureComplete && termsAcknowledged}`)
    }

    const navHome = () => {
        navigate('/')
    }

    useEffect(() => {
    }, [props.user])
    return (
        <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center'}}>
            <Col xs={24} sm={22} md={18} lg={12} style={{display: 'flex', flexDirection: 'column'}}>
                <Row>
                    <Col span={24} style={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
                        <Typography style={{ color: 'black', fontWeight: '600', fontSize: '18px' }}>Add A Vehicle</Typography>
                    </Col>
                </Row>
                <Row style={{ marginTop: '20px' }}>
                    <Card style={{ width: '100%' }}>
                        {!captureComplete && !termsAcknowledged &&
                            <Row>
                                <Col span={24} style={{ display: 'flex', flexDirection: 'column' }}>
                                    <Typography.Paragraph style={{ textAlign: 'start' }}>Tap the button below to start the
                                        process of adding a new vehicle to your account. Once you add this vehicle, the driver
                                        will be able to use the payment methods associated with this account to pay for
                                        goods and services. Before you start, make sure your vehicle is parked in a safe area, and that the
                                        license plate is unobstructed. </Typography.Paragraph>
                                </Col>
                                <Col span={24}>
                                    <Button onClick={showAddVehicleModal} block type={'primary'}>Let's Get Started</Button>
                                </Col>
                            </Row>
                        }
                        {captureComplete && termsAcknowledged &&
                            <Row>
                                <Col span={24} style={{ display: 'flex', flexDirection: 'column' }}>
                                    <Typography.Paragraph style={{ textAlign: 'start' }}>Vehicle review in progress. We'll update you via text message within 24 hours.</Typography.Paragraph>
                                </Col>
                                <Col span={24}>
                                    <Button onClick={navHome} block type={'primary'}>Home</Button>
                                </Col>
                            </Row>
                        }
                    </Card>
                </Row>
                <AddVehicleModal
                    visible={addVehicleModalVisible}
                    setVisible={setAddVehicleModalVisible}
                    onComplete={addVehicleComplete}
                    user={props.user}
                    captured={captureComplete}
                    setCaptured={setCaptureComplete}
                    termsAcknowledged={termsAcknowledged}
                    setTermsAcknowledged={setTermsAcknowledged}
                    onError={props.onError}
                />
            </Col>
        </div>
    )
}
export default AddVehicleScreen

import React, {useEffect, useRef} from 'react';
import {Button, Collapse, CollapseProps, Modal, Row, Typography,} from 'antd';
import Bowser from 'bowser';

import {MediaPermissionsError, MediaPermissionsErrorType, requestMediaPermissions,} from 'mic-check';

const browser = Bowser.getParser(window.navigator.userAgent);

enum DialogType {
    explanation = 'explanation',

    systemDenied = 'systemDenied',
    userDenied = 'userDenied',
    trackError = 'trackError',
}

const CameraPermissions: React.FC = () => {
    const [showDialog, setShowDialog] = React.useState<DialogType | null>(null);

    const [videoAllowed, setVideoAllowed] = React.useState<boolean>(!!localStorage.getItem("videoAllowed"));

    const [errorDetails, setErrorDetails] = React.useState<
        MediaPermissionsError | undefined
    >();


    // Create wrapper refs to access values even during setTimeout
    // https://github.com/facebook/react/issues/14010
    const showDialogRef = useRef(showDialog);
    showDialogRef.current = showDialog;
    const videoAllowedRef = useRef(videoAllowed);
    videoAllowedRef.current = videoAllowed;




    const checkForExplanationDialog = () => {
        if (
            (!videoAllowedRef.current) &&
            showDialogRef.current === null
        )
            setShowDialog(DialogType.explanation);
    };

    const checkMediaPermissions = () => {
        requestMediaPermissions({ audio: false, video: true })
            .then(() => {
                localStorage.setItem("videoAllowed", "true")
                setVideoAllowed(true);
                setShowDialog(null);
            })
            .catch((error: MediaPermissionsError) => {
                console.log('MediaOnboardingDialog: ', error);
                if (
                    error.type ===
                    MediaPermissionsErrorType.SystemPermissionDenied
                ) {
                    // user denied permission
                    setShowDialog(DialogType.systemDenied);
                } else if (
                    error.type ===
                    MediaPermissionsErrorType.UserPermissionDenied
                ) {
                    // browser doesn't have access to devices
                    setShowDialog(DialogType.userDenied);
                } else if (
                    error.type ===
                    MediaPermissionsErrorType.CouldNotStartVideoSource
                ) {
                    // most likely when other apps or tabs are using the cam/mic (mostly windows)
                    setShowDialog(DialogType.trackError);
                } else {
                }
                setErrorDetails(error);
            });

    };
    useEffect(() => {
        setTimeout(() => {
            checkForExplanationDialog();
        }, 500);
    }, [])

    const _renderTryAgain = (text?: string) => {
        return (
            <div style={{ width: '100%', marginTop: 20 }}>
                <Button
                    onClick={() => {
                        if (browser.getBrowserName() === 'Safari') {
                            // If on Safari, rechecking permissions results in glitches so just refresh the page
                            window.location.reload();
                        } else {
                            checkMediaPermissions();
                        }
                    }}
                    color="primary"
                    style={{ float: 'right' }}
                >
                    {text ? text : 'Retry'}
                </Button>
            </div>
        );
    };

    const _renderErrorMessage = () => {
        if (!errorDetails) return null;
        const items: CollapseProps['items'] = [
            {
                key: '1',
                label: <Typography style={{ color: 'red', fontWeight: '600' }}>Error Details</Typography>,
                children: <Typography >
                    {errorDetails.name}: {errorDetails.message}
                </Typography>,

            },

        ];

        return (
            <div style={{ marginTop: 10 }}>
                <Collapse items={items} />
            </div>
        );
    };

    const _renderExplanationDialog = () => {
        return (
            <div>
                <h4>
                    Enable Temporary Camera Access
                </h4>
                <Typography>
                    YewPay needs to use your camera to verify it's you. We want to make sure only <b>YOU</b> have access to your account!
                </Typography>
                <Button type="primary" onClick={checkMediaPermissions} block style={{marginTop: 15}}>Enable Camera Access</Button>
            </div>
        );
    };

    const _renderUserDeniedDialog = () => {
        return (
            <div>
                <h4>
                    Camera blocked
                </h4>
                <Typography>
                    YewPay requires access to your camera.{' '}
                    {browser.getBrowserName() !== 'Safari' && (
                        <Typography>
                            Click the camera blocked icon{' '}
                            <img
                                alt="icon"
                                src={
                                    './blocked_icon.svg'
                                }
                                style={{ display: 'inline' }}
                            />{' '}
                            in your browser's address bar.
                        </Typography>
                    )}
                </Typography>
                {_renderErrorMessage()}
            </div>
        );
    };

    const _renderSystemDeniedDialog = () => {
        return (
            <div>
                <h4>
                    Can't use your camera
                </h4>
                <Typography>
                    Your browser might not have access to your camera. To fix this problem, check Settings.
                </Typography>
                {_renderErrorMessage()}
            </div>
        );
    };

    const _renderTrackErrorDialog = () => {
        return (
            <div>
                <h4>
                    Can't start your camera
                </h4>
                <Typography>
                    Another application (Zoom, Webex) or browser tab (Google
                    Meet, Messenger Video) might already be using your webcam.
                    Please turn off other cameras before proceeding.
                </Typography>
                {_renderErrorMessage()}
            </div>
        );
    };

    const _renderDialogContent = () => {
        switch (showDialog) {
            case DialogType.explanation:
                return _renderExplanationDialog();
            case DialogType.systemDenied:
                return _renderSystemDeniedDialog();
            case DialogType.userDenied:
                return _renderUserDeniedDialog();
            case DialogType.trackError:
                return _renderTrackErrorDialog();
        }
    };
    const _renderFooter = () => {
        switch (showDialog) {
            case DialogType.explanation:
                return null;
            case DialogType.systemDenied:
            case DialogType.userDenied:
            case DialogType.trackError:
                return _renderTryAgain();
        }
    };
    const footer = <Row>
        {showDialog && _renderFooter()}
    </Row>
    return (
        <Modal open={!!showDialog} closable={false} footer={footer} mask={true} centered>
            {showDialog && _renderDialogContent()}
        </Modal>
    );
};

export default CameraPermissions;
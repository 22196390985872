import GeneralService from './GeneralService'
import {Component} from 'react'


export class PromoService extends Component {
  public static getPromos(location_id: any, accessToken: any, loadingHandler: any) {
    if (location_id) {
      return GeneralService.get(`/promo/promos/location/${location_id}`, accessToken, loadingHandler, JSON, GeneralService.USER_ENDPOINT)
    } else {
      return GeneralService.get(`/promo/promos`, accessToken, loadingHandler, JSON, GeneralService.USER_ENDPOINT)
    }
  }
  public static activatePromo(promo_id: number, accessToken: any, loadingHandler: any) {
    return GeneralService.put(`/promo/activate/${promo_id}`, '', accessToken, loadingHandler, JSON, GeneralService.USER_ENDPOINT)
  }
  public static deactivatePromo(promo_id: number, accessToken: any, loadingHandler: any) {
    return GeneralService.put(`/promo/deactivate/${promo_id}`, '', accessToken, loadingHandler, JSON, GeneralService.USER_ENDPOINT)
  }
}
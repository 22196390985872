import React, {type FC, useEffect, useState} from 'react'
import {Button, Col, Modal, Row, Typography} from 'antd'
import {User} from '../models/User';
import {PaymentMethod} from "../models/PaymentMethodTypes";
import PaymentMethodsList from "./PaymentMethodsList";
import {PaymentMethodsService} from "../services/PaymentMethodsService";
import {Mechanism} from "../models/MechanismTypes";
import {useNavigate} from "react-router-dom";

interface ChooseCardModalProps {
    visible: boolean
    setVisible: (visible: boolean) => void
    onComplete: (payment_method: PaymentMethod) => void
    user: User
    onError: (description: string, title?: string) => void
    mechanism: Mechanism
    currentPaymentMethod?: PaymentMethod
    newCardRedirectRoute?: string
}

const ChooseCardModal: FC<ChooseCardModalProps> = (props) => {
    const [loading, setLoading] = useState(false);
    const [paymentMethods, setPaymentMethods] = useState<any>([]);
    const navigate = useNavigate()


    useEffect(() => {
        if (props.mechanism) {
            PaymentMethodsService.getMechanismEligiblePaymentMethods(props.mechanism.id, 0, 100, props.user.access_token, setLoading).subscribe((data: PaymentMethod[]) => {
                let filteredData = data
                if (props.currentPaymentMethod) {
                    filteredData = data.filter(paymentMethod => paymentMethod.id != props.currentPaymentMethod?.id);
                }
                setPaymentMethods(filteredData);
            }, (error: any) => {
                console.error(`Error fetching cards: ${error}`);
                props.onError(error, `Error Fetching Cards`);
            });
        }
    }, [props.user, props.mechanism, props.currentPaymentMethod])

    const onCancel = () => {
        props.setVisible(false);
    }

    const goToAddCard = (): void => {
        navigate(`/cards/new`, { state: { redirectRoute: props.newCardRedirectRoute } })
    }

    return (
        <Modal
            open={props.visible}
            onCancel={onCancel}
            footer={null}
            style={{ display: 'flex', flexDirection: 'column', top: 0, marginTop: '10px' }}
            bodyStyle={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
            <Row>
                <Col span={24}>
                    <div style={{ width: '100%' }}>
                        <div style={{ padding: '20px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <h2 style={{ fontWeight: '600', margin: 0 }}>Choose A Card</h2>
                            <p>Select the card that you would like to be charged when you pay with {props.mechanism?.mechanism_type == 'FACE' ? 'your face' : 'this vehicle'}.</p>
                        </div>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    {paymentMethods?.length > 0 ? (
                        <PaymentMethodsList onClick={props.onComplete} user={props.user}
                                            paymentMethods={paymentMethods}/>) :
                        (<Col span={24} style={{width: '100%', textAlign: 'center'}}>
                            <Typography>No Cards Available</Typography>
                        </Col>)
                    }
                </Col>
            </Row>
            <Row style={{marginTop: '40px'}}>
                <Col span={24}>
                    <Button block type={'primary'} onClick={goToAddCard}>Add New Card</Button>
                </Col>
            </Row>
        </Modal>

    )
}

export default ChooseCardModal;

import {Divider} from 'antd'
import VehicleSummary from './VehicleSummary'
import React, {type FC} from 'react'
import {Mechanism} from '../models/MechanismTypes'
import {User} from '../models/User'
import {EnrollVehicle} from '../models/EnrollVehicle'
import EnrolledVehicleSummary from './EnrolledVehicleSummary'

interface VehicleListProps {
  user: User
  vehicleList: Mechanism[] | any
  enrolledVehicleList: EnrollVehicle[] | any
  onClick: any
  onAccept: any
  onDecline: any
  limit?: number
}

const VehicleList: FC<VehicleListProps> = (props) => {
  const limit: any = props.limit || Infinity;
  const enrolledVehicles = props.enrolledVehicleList.slice(0, props.limit).map((vehicle: EnrollVehicle, index: number) => (
    <div key={index}>
      <EnrolledVehicleSummary vehicle={vehicle}/>
      {index < props.enrolledVehicleList.length - 1 && <Divider />}
    </div>
  ))
  const confirmedVehicles = props.vehicleList.slice(0, props.limit).map((vehicle: Mechanism, index: number) => (
    <div key={enrolledVehicles.length + index}>
      <VehicleSummary user={props.user} mechanism={vehicle} onClick={props.onClick} onAccept={props.onAccept} onDecline={props.onDecline} />
      {index < props.vehicleList.length - 1 && <Divider />}
    </div>
  ))
  const combinedVehicles: any = enrolledVehicles.length > 0
      ? [...enrolledVehicles, <Divider key="between-lists-divider" />, ...confirmedVehicles]
      : [...enrolledVehicles, ...confirmedVehicles];

  return combinedVehicles.slice(0, limit * 2 + 1); // Adjusted the slice limit to account for the divider
}

export default VehicleList

import React, {useEffect, useRef, useState} from 'react'
import {BrowserRouter, Route, Routes} from 'react-router-dom'
import './App.css'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import AuthComponent from './auth/AuthComponent'
import HomeComponent from './home/HomeComponent'
import AllTransactionsScreen from './transactions/AllTransactionsScreen'
import TransactionDetailScreen from './transactions/TransactionDetailScreen'
import TransactionsRoute from './transactions/TransactionsRoute'
import AllPaymentMethodsScreen from './paymentMethods/AllPaymentMethodsScreen'
import PaymentMethodDetails from './paymentMethods/PaymentMethodDetails'
import PaymentMethodsRoute from './paymentMethods/PaymentMethodsRoute'
import MechanismsRoute from './mechanisms/MechanismsRoute'
import AllMechanismsScreen from './mechanisms/AllMechanismsScreen'
import AddVehicleScreen from './mechanisms/AddVehicleScreen'
import VehiclesScreen from './mechanisms/VehiclesScreen'
import VehiclesRoute from './mechanisms/VehiclesRoute'
import VehicleDetailScreen from './mechanisms/VehicleDetailScreen'
import ProfileRoute from './profile/ProfileRoute'
import ProfileScreen from './profile/ProfileScreen'
import type {TourProps} from 'antd';
import {ConfigProvider, notification, Tour} from 'antd';
import {User} from './models/User'
import AddCardScreen from "./paymentMethods/AddCardScreen";
import Menu from './Menu'
import {UserService} from './services/UserService'
import FaceDetailScreen from "./mechanisms/FaceDetailScreen";
import RewardsRoute from './rewards/RewardsRoute'
import RewardsScreen from './rewards/RewardsScreen'
import LoyaltyRoute from './rewards/LoyaltyRoute'
import LoyaltyScreen from './rewards/LoyaltyScreen'
import LoyaltyDetailScreen from './rewards/LoyaltyDetailScreen'
import LoyaltyActivityScreen from './rewards/LoyaltyActivityScreen'
import NoCardScreen from "./paymentMethods/NoCardScreen";
import IntroScreen from "./home/IntroScreen";

declare global {
    interface Window {
        heap: any;
    }
}


function App(): any {
    const [user, setUser] = useState<User | null>(null)
    const installPrompt = useRef<any>();
    const [tourOpen, setTourOpen] = useState<boolean>(false);
    const tourRefs: any = {
        homeRef: useRef<any>(null),
        identifiersRef: useRef<any>(null),
        rewardsRef: useRef<any>(null),
        cardsRef: useRef<any>(null),
        profileRef: useRef<any>(null)
    };

    const handleUserLogin = (userData: any): void => {
        setUser(userData)
        localStorage.setItem("accessToken", userData.access_token);
        localStorage.setItem("refreshToken", userData.refresh_token);
        window.heap.identify(userData.id);
    }
    const refreshUser = () => {
        const accessToken = localStorage.getItem("accessToken")
        if (!accessToken) {
            return
        }
        UserService.refreshUser('', accessToken, null).subscribe((refreshUser: User) => {
            const newUser: User = {
                ...user,
                ...refreshUser
            }
            newUser.access_token = newUser.access_token || localStorage.getItem("accessToken") || ""
            newUser.refresh_token = newUser.refresh_token || localStorage.getItem("refreshToken") || ""
            handleUserLogin(newUser)
        }, (error: any) => {
            console.error(`Error refreshing user ${error}`)
            window.heap.track('user_refresh_error', { 'message': error });
        })
    }
    useEffect(() => {
        window.addEventListener('storage', refreshUser)
        refreshUser()
    }, [])

    const handleUserLogout = (): void => {
        localStorage.removeItem("accessToken")
        localStorage.removeItem("refreshToken")
        setUser(null)
    }
    const [api, contextHolder] = notification.useNotification();
    const errorNotification = (description: string, title: string = 'Error Occurred') => {
        window.heap.track(title, { 'message': description });
        api['error']({
            message: title,
            description: description,
            placement: "topRight",
            duration: 0,

        });
    };
    useEffect(() => {
        window.addEventListener('beforeinstallprompt', (e) => {
            installPrompt.current = e
        });
    }, [])
    const showInstallPrompt = () => {
        if (!installPrompt.current || localStorage.getItem("installPrompted")) {
            return
        }
        localStorage.setItem("installPrompted", "true")
        installPrompt.current.prompt();  // Wait for the user to respond to the prompt
        installPrompt.current.userChoice
            .then((choice: any) => {
                window.heap.track('install-prompt', { 'choice': choice.outcome });
            })
    }

    const tourSteps: TourProps['steps'] = [
        {
            title: 'Welcome To YewPay!',
            description: 'Let\'s go over where some things are.',
            target: null
        },
        {
            title: 'Home',
            description: 'Your Home dashboard shows recent activity, YewPay merchants near you, and any urgent tasks.',
            placement: 'top',
            target: () => tourRefs.homeRef?.current
        },
        {
            title: 'Identifiers',
            description: 'Manage your vehicles and biometrics here.',
            placement: 'top',
            target: () => tourRefs.identifiersRef?.current
        },
        {
            title: 'Rewards',
            description: 'View and manage your merchant rewards, offers, and credits from here.',
            placement: 'top',
            target: () => tourRefs.rewardsRef?.current
        },
        {
            title: 'Cards',
            description: 'Add, disable, or remove payment methods in this last tab.',
            placement: 'top',
            target: () => tourRefs.cardsRef?.current
        },
        {
            title: 'Profile',
            description: 'Set your notification settings and contact support from Profile.',
            placement: 'top',
            target: () => tourRefs.profileRef?.current
        },
        {
            title: 'All Set!',
            description: 'You\'re ready to start using YewPay!',
            target: null
        },
    ]

    const showTour = () => {
        if (!localStorage.getItem('tourShown')) {
            setTourOpen(true);
            localStorage.setItem('tourShown', 'true')
            window.heap.track('Tour Shown')
        }
    }



    return (
        <ConfigProvider theme={{ token: { fontFamily: 'Montserrat', colorPrimary: '#7ac968' }}}>
            <div className="App">
                {contextHolder}
                <BrowserRouter>
                    <header className="App-header">
                        <Routes>
                            {!user && !localStorage.getItem("accessToken") &&
                                <Route path={'*'} element={<AuthComponent onUserLogin={handleUserLogin} onError={errorNotification} />} />
                            }
                            {user &&
                                <Route path={'/'}>
                                    <Route index element={<HomeComponent user={user} onError={errorNotification} refreshUser={refreshUser} showInstall={showInstallPrompt} tourRefs={tourRefs} showTour={showTour}/>} />
                                    <Route path={'/no_card'} element={<NoCardScreen user={user} onError={errorNotification} />} />
                                    <Route path={'/intro'} element={<IntroScreen user={user} onError={errorNotification} />} />
                                    <Route path={'/transactions'} element={<TransactionsRoute />}>
                                        <Route index element={<AllTransactionsScreen user={user} onError={errorNotification} />} />
                                        <Route path={':id'} element={<TransactionDetailScreen user={user} onError={errorNotification} />} />
                                    </Route>
                                    <Route path={'/cards'} element={<PaymentMethodsRoute />}>
                                        <Route index element={<AllPaymentMethodsScreen user={user} onError={errorNotification} />} />
                                        <Route path={':id'} element={<PaymentMethodDetails user={user} onError={errorNotification} />} />
                                        <Route path={'new'} element={<AddCardScreen user={user} onError={errorNotification} />} />
                                    </Route>
                                    <Route path={'/identifiers'} element={<MechanismsRoute />}>
                                        <Route index element={<AllMechanismsScreen user={user} onError={errorNotification} />} />
                                        <Route path={'vehicles'} element={<VehiclesRoute />}>
                                            <Route index element={<VehiclesScreen user={user} onError={errorNotification} />} />
                                            <Route path={':id'} element={<VehicleDetailScreen user={user} onError={errorNotification} />} />
                                            <Route path={'new'} element={<AddVehicleScreen user={user} onError={errorNotification} />} />
                                        </Route>
                                        <Route path={'face'} element={<FaceDetailScreen user={user} onError={errorNotification} />} />
                                    </Route>
                                    <Route path={'/profile'} element={<ProfileRoute />}>
                                        <Route index element={<ProfileScreen user={user} onError={errorNotification} handleUserLogout={handleUserLogout} />} />
                                    </Route>
                                    <Route path={'/rewards'} element={<RewardsRoute />}>
                                        <Route index element={<RewardsScreen user={user} onError={errorNotification} />} />
                                        <Route path={'loyalty'} element={<LoyaltyRoute />}>
                                            <Route index element={<LoyaltyScreen user={user} onError={errorNotification} />} />
                                            <Route path={':id'} element={<LoyaltyDetailScreen user={user} onError={errorNotification} />} />
                                            <Route path={':id/activity'} element={<LoyaltyActivityScreen user={user} onError={errorNotification} />} />
                                        </Route>
                                    </Route>
                                </Route>
                            }
                        </Routes>
                    </header>
                    {user &&
                        <Menu tourRefs={tourRefs} />
                    }
                    {user &&
                        <Tour open={tourOpen} onClose={() => setTourOpen(false)} steps={tourSteps} />
                    }
                </BrowserRouter>
            </div>
        </ConfigProvider>
    )
}

export default App

import React, {useEffect, useState} from 'react';
import {Button, Card, Col, Divider, Row, Typography} from 'antd';
import {User} from '../models/User';
import {useNavigate} from 'react-router-dom'
import {Promo} from "../models/Promo";
import {PromoService} from "../services/PromoService";
import {formatDate, isDateFuture} from "../utils/DateUtils";

interface PromosPreviewProps {
    user: User
    onError: (description: string, title?: string) => void
    location_id?: any
    promos: any
    setPromos: (promos: any[]) => void
}
const PromosPreview: React.FC<PromosPreviewProps> = (props) => {
    const [loading, setLoading] = useState<boolean>(false)
    const [promosUpdated, setPromosUpdated] = useState<number>(0)
    const [promoButtonLoading, setPromoButtonLoading] = useState<any>(false);
    const navigate = useNavigate()

    useEffect(() => {
        const initialLoad = loading
        getPromos(initialLoad)
    }, [props.user, promosUpdated])

    const getPromos = (initialLoad: boolean) => {
        PromoService.getPromos(props.location_id, props.user.access_token, initialLoad ? setLoading : null).subscribe(
            (data: Promo[]) => {
                props.setPromos([...data])
            },
            (error: any) => {
                console.error(`Error getting credits ${error}`)
                props.onError(error)
            }
        )
    }

    const activatePromo = (promo_id: number) => {
        setPromoButtonLoading(true)
        PromoService.activatePromo(promo_id, props.user.access_token, setPromoButtonLoading).subscribe((data: any) => {
                window.heap.track('Promo Activate Success', {promoId: promo_id})
                setPromosUpdated(promosUpdated + 1)
                getPromos(false)
            },
            (error: any) => {
                console.error(`Error activating promo ${error}`)
                window.heap.track('Promo Activate Error', {promoId: promo_id, error: error})
                props.onError(error)
                setPromoButtonLoading(false)
            }
        )
    }

    let promoRender: any = props.promos.map((promo: Promo, index: number) => {
        let deal = ""
        switch (promo.promo_type) {
            case "PCT_OFF":
                deal = `${promo.promo_value * 100}% off `
                break;

            default:
                return
        }
        let promoActionButton;
        if (promo.status == 'ACTIVATED') {
            promoActionButton = <Button onClick={() => {}} disabled={true}>Claimed</Button>
        } else {
            promoActionButton = <Button type={'primary'} onClick={() => {activatePromo(promo.id)}} disabled={promoButtonLoading} loading={promoButtonLoading}>Claim</Button>
        }
        return <Row key={index}>
            <Col span={18}>
                <Typography>{props.location_id ? '' : `${promo.location.location_name} - `}<span style={{ fontWeight: 'bold' }}>{deal}</span></Typography>
                {isDateFuture(promo.date_start) ? (
                    <Typography>Valid {formatDate(promo.date_start, false)} - {formatDate(promo.date_end, false)}</Typography>
                ) : (
                    <Typography>Expires {formatDate(promo.date_end, false)}</Typography>
                )}
            </Col>
            <Col span={6} style={{ display: 'flex', flexDirection: 'column', alignItems: 'end', justifyContent: 'center' }}>
                {promoActionButton}
            </Col>
            {index < props.promos.length - 1 && <Divider />}
        </Row>
    })

    return (
        <Card style={{ overflowY: 'auto', textAlign: 'left' }} title={'Promos'} loading={loading}>
            {promoRender.length > 0 ? promoRender : "No promos available at this time"}
        </Card>
    );
};

export default PromosPreview;
import React from 'react';
import {Button, Card, Col, Divider, Progress, Row, Typography} from 'antd';
import {LoyaltyBalance, LoyaltyRedemption, LoyaltyTier} from '../models/Loyalty';
import {formatCurrency} from '../utils/NumberUtils';

interface LoyaltyRedemptionTiersComponentProps {
    activateLoyalty: (loyaltyID: number) => void
    loyaltyBalance: LoyaltyBalance | undefined
    loyaltyBalanceLoading: boolean
    hideLocation?: boolean
}
const formatPercent = (points_amount: number, points_threshold: number) => {
    return 100 * points_amount /points_threshold 
}
const LoyaltyRedemptionTiersComponent: React.FC<LoyaltyRedemptionTiersComponentProps> = (props) => {
    if (props.loyaltyBalance == undefined) {
        return <div></div>
    }
    const redeemed = props.loyaltyBalance.available_rewards.map((redemption: LoyaltyRedemption, index: number) => (
        <div key={index}>
            <Row>
                <Col span={18}>
                    <Typography><span style={{fontWeight: '600'}}>{formatCurrency(redemption.monetary_value)}</span> off</Typography>
                </Col>
                <Col span={6} style={{ display: 'flex', flexDirection: 'column', alignItems: 'end' }}>
                        <Button disabled>Activated</Button>
                </Col>
            </Row>
            {(index < (props.loyaltyBalance?.available_rewards.length || 0) - 1 || (props.loyaltyBalance?.redemption_tiers.length || 0) > 0) && <Divider />}
        </div>
    ))
    const redemption_tiers = props.loyaltyBalance.redemption_tiers.map((tier: LoyaltyTier, index: number) => (
        <div key={index}>
            <Col span={24}>
                <Row style={{display: 'flex', 'alignItems': 'center'}}>
                    <div style={{flex: 1}}>
                        <Row>
                            <Col span={24}>
                                <Typography><span style={{fontWeight: '600'}}>{formatCurrency(tier.monetary_value)}</span> off <span style={{fontSize: '12px'}}>({props.loyaltyBalance?.points_amount}/{tier.points_threshold} pts)</span></Typography>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <Progress percent={formatPercent(props.loyaltyBalance?.points_amount || 0, tier.points_threshold)} />
                            </Col>
                        </Row>
                    </div>
                    <div style={{ flex: '0 0 auto', flexDirection: 'column', alignItems: 'end' }}>
                        {(props.loyaltyBalance?.points_amount || 0) >= tier.points_threshold &&
                            <Button type={'primary'} onClick={() => { props.activateLoyalty(tier.id) }}>Activate</Button>
                        }
                    </div>
                </Row>
            </Col>
            {index < (props.loyaltyBalance?.redemption_tiers.length || 0) - 1 && <Divider />}
        </div>
    ))
    return (
    <Card style={{ height: 'auto', overflowY: 'auto', width: '100%', textAlign: 'left' }} bodyStyle={{ paddingTop: 0 }} headStyle={{ marginBottom: 12 }} title={'Rewards'} loading={props.loyaltyBalanceLoading}>
        <Row>
            <Col span={24}>
                {redeemed}
                {redemption_tiers}
            </Col>
        </Row>
    </Card>)
};

export default LoyaltyRedemptionTiersComponent;
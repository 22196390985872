import GeneralService from './GeneralService'
import {Component} from 'react'

export class MechanismsService extends Component {
  public static getVehicles(offset: number, limit: number, accessToken: any, loadingHandler: any) {
    return GeneralService.get(`/mechanisms/vehicles/${offset}/${limit}`, accessToken, loadingHandler, JSON)
  }
  public static getPendingVehicles(offset: number, limit: number, accessToken: any, loadingHandler: any) {
    return GeneralService.get(`/mechanisms/vehicles/pending/${offset}/${limit}`, accessToken, loadingHandler, JSON)
  }
  public static getEnrollmentPendingVehicles(offset: number, limit: number, accessToken: any, loadingHandler: any) {
    return GeneralService.get(`/mechanisms/vehicles/enroll/pending/${offset}/${limit}`, accessToken, loadingHandler, JSON)
  }
  public static getVehicleById(id: any, accessToken: any, loadingHandler: any) {
    return GeneralService.get(`/mechanisms/vehicles/${id}`, accessToken, loadingHandler, JSON)
  }
  public static getById(mechanism_id: any, accessToken: any, loadingHandler: any) {
    return GeneralService.get(`/mechanisms/${mechanism_id}`, accessToken, loadingHandler, JSON)
  }
  public static renameMechanism(mechanism: any, accessToken: any, loadingHandler: any) {
    return GeneralService.put(`/mechanisms/update/nickname`, mechanism, accessToken, loadingHandler, JSON)
  }
  public static updateStatus(mechanism: any, accessToken: any, loadingHandler: any) {
    return GeneralService.put(`/mechanisms/update/status`, mechanism, accessToken, loadingHandler, JSON)
  }
  public static deleteVehicle(vehicle: any, accessToken: any, loadingHandler: any) {
    return GeneralService.delete(`/mechanisms/vehicles`, vehicle, accessToken, loadingHandler, JSON)
  }
  public static getFace(accessToken: any, loadingHandler: any) {
    return GeneralService.get(`/mechanisms/face`, accessToken, loadingHandler, JSON)
  }
  public static enrollVehicle(vehicle: any, accessToken: any, loadingHandler: any) {
    return GeneralService.put(`/mechanisms/vehicles/enroll`, vehicle, accessToken, loadingHandler, JSON)
  }
}
import {User} from "../models/User";
import React, {FC, useEffect, useState} from "react";
import {Button, Card, Col, Row, Typography} from "antd";
import {Mechanism} from "../models/MechanismTypes";
import {PaymentMethodsService} from "../services/PaymentMethodsService";
import {PaymentMethod} from "../models/PaymentMethodTypes";
import PaymentMethodSummary from "../paymentMethods/PaymentMethodSummary";
import {useNavigate} from "react-router-dom";
import ChooseCardModal from "../paymentMethods/ChooseCardModal";

interface MechanismPaymentMethodProps {
    user: User
    onError: (description: string, title?: string) => void
    mechanism: Mechanism
    newCardRedirectRoute?: string
}

const MechanismPaymentMethodComponent: FC<MechanismPaymentMethodProps> = (props) => {
    const [loading, setLoading] = useState<any>(true);
    const navigate = useNavigate()
    const [paymentMethod, setPaymentMethod] = useState<any>(null);
    const [chooseCardModalVisible, setChooseCardModalVisible] = useState<any>(false)

    const goToPaymentMethod = (paymentMethod: PaymentMethod): void => {
        navigate(`/cards/${paymentMethod.id}`)
    }

    const chooseCard = (chosenPaymentMethod: PaymentMethod): void =>  {
        setChooseCardModalVisible(false);
        const original = paymentMethod;
        setPaymentMethod(chosenPaymentMethod);
        PaymentMethodsService.setMechanismPaymentMethod(props.mechanism.id, JSON.stringify(chosenPaymentMethod), props.user.access_token, null).subscribe((data: any) => {
        }, (error: any) => {
            console.error(`Error updating mechanism payment method: ${error}`);
            props.onError(error, `Error Updating Payment Method`);
            setPaymentMethod(original);
        });
    }

    useEffect(() => {
        if (props.mechanism) {
            setLoading(true);
            PaymentMethodsService.getMechanismPaymentMethod(props.mechanism.id, props.user.access_token, setLoading).subscribe((data: PaymentMethod) => {
                setPaymentMethod(data)
            }, (error: any) => {
                console.error(`Error fetching payment method: ${error}`);
                props.onError(error, `Error Fetching Mechanism Payment Method`);
            })
        }
        }, [props.mechanism])

    const showChooseCardModal = () => {
        setChooseCardModalVisible(true)
    }

    return (
        <div style={{width: '100%'}}>
            <Card style={{ width: '100%', textAlign: 'left' }} title={'Payment Method'} loading={loading} extra={<a onClick={() => showChooseCardModal()}>{paymentMethod ? 'Change' : ''}</a>}>
                <Row>
                    {paymentMethod ? (
                        <Col span={24}>
                            <PaymentMethodSummary user={props.user} paymentInfo={paymentMethod} onClick={goToPaymentMethod} />
                        </Col>) : (
                            <Col span={24} style={{width: '100%', textAlign: 'center'}}>
                                <Typography>No Card Attached</Typography>
                            </Col>
                    )
                    }
                    {!paymentMethod && !loading &&
                        <Col span={24} style={{ marginTop: '20px' }}>
                            <Button block onClick={() => showChooseCardModal()}>
                                Choose Card
                            </Button>
                        </Col>
                    }
                </Row >
            </Card>
            <ChooseCardModal visible={chooseCardModalVisible} setVisible={setChooseCardModalVisible} onComplete={chooseCard}
                             user={props.user} onError={props.onError} mechanism={props.mechanism} currentPaymentMethod={paymentMethod} newCardRedirectRoute={props.newCardRedirectRoute}/>
        </div>
    );
};

export default MechanismPaymentMethodComponent;
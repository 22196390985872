import React, {type FC, useEffect, useState} from 'react'
import {Button, Card, Col, Row, Typography} from 'antd'
import TransactionsListComponent from '../common/TransactionsList'
import {useNavigate} from 'react-router-dom'
import {TransactionsService} from '../services/TransactionsService'
import {Mechanism} from '../models/MechanismTypes'
import {PaymentMethod} from '../models/PaymentMethodTypes'
import {Transaction} from '../models/TransactionTypes'
import {User} from '../models/User'

interface ActivitySummaryProps {
  user: User
  mechanism?: Mechanism
  payment_method?: PaymentMethod
  onError: (description: string, title?: string) => void
}

const ActivitySummaryComponent: FC<ActivitySummaryProps> = (props) => {
  const navigate = useNavigate()
  const [transactions, setTransactions] = useState<Transaction[]>([])
  const [loading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    // TODO don't hardcode bounds
    if (props.mechanism) {
      TransactionsService.getTransactionsByMechanism(props.mechanism.id, props.user.access_token, 0, 3, setLoading).subscribe((data: Transaction[]) => {
        setTransactions(data)
      }, (error: any) => {
        console.error(`Error fetching transactions: ${error}`);
        props.onError(error, `Error Fetching Transactions`);

      })
    } else if (props.payment_method) {
      TransactionsService.getTransactionsByPaymentMethod(props.payment_method.id, props.user.access_token, 0, 3, setLoading).subscribe((data: Transaction[]) => {
        setTransactions(data)
      }, (error: any) => {
        console.error(`Error fetching transactions: ${error}`);
        props.onError(error, `Error Fetching Transactions`);
      })
    } else {
      TransactionsService.getTransactions(0, 3, props.user.access_token, setLoading).subscribe((data: Transaction[]) => {
        setTransactions(data)
      }, (error: any) => {
        console.error(`Error fetching transactions: ${error}`);
        props.onError(error, `Error Fetching Transactions`);
      })
    }
  }, [props.user.access_token, props.payment_method, props.mechanism])
  const goToAllTransactions = () => {
    navigate('/transactions', { state: { mechanism: props.mechanism, payment_method: props.payment_method } })
  }

  const goToTransaction = (transaction: Transaction) => {
    navigate(`/transactions/${transaction.payment.id}`)
  }

  return (
    <Card style={{ width: '100%', textAlign: 'left' }} title={'Recent Activity'} extra={<a onClick={goToAllTransactions}>{transactions.length > 0 ? 'View All' : ''}</a>} loading={loading}>
      <Row>
        <Col span={24}>
          {transactions.length > 0 &&
            <TransactionsListComponent transactions={transactions} user={props.user} onClick={goToTransaction} />
          }
          {transactions.length == 0 &&
            <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Typography>No recent activity</Typography>
            </div>
          }
        </Col>
        <Col span={24} style={{ marginTop: '20px' }}>
          {transactions.length > 0 &&
            <Button block onClick={goToAllTransactions}>
              See All Transactions
            </Button>
          }
        </Col>
      </Row>
    </Card>
  )
}

export default ActivitySummaryComponent

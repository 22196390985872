import { Divider } from 'antd'
import TransactionSummaryComponent from './TransactionSummaryComponent'
import { type FC } from 'react'
import { User } from '../models/User'
import { Transaction } from '../models/TransactionTypes'

interface TransactionsListProps {
  user: User
  transactions: Transaction[] | any
  onClick: any
}

const TransactionsListComponent: FC<TransactionsListProps> = (props) => {
  return props.transactions.map((transaction: Transaction, index: number) => (
    <div key={index}>
      <TransactionSummaryComponent user={props.user} transaction={transaction} onClick={props.onClick} />
      {index < props.transactions.length - 1 && <Divider />}
    </div>
  ))
}

export default TransactionsListComponent

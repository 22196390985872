import React, {useCallback, useEffect, useRef, useState} from 'react';
import {Button, Modal, Row, Spin, Steps} from 'antd';
import {PhoneOutlined, SmileOutlined} from '@ant-design/icons';
import {enrollPhone, PhoneNumberInput} from './PhoneNumberInput';
import PhoneVerification from './PhoneVerification'
import EnrollFace from './EnrollFace';
import {UserService} from '../services/UserService';
import {User} from '../models/User';
import CameraPermissions from "../utils/CameraPermissions";
import Webcam from "react-webcam";

const RegisterAccountModal = (props: any) => {
  const [current, setCurrent] = useState(0); // Major steps: 0 for phone, 1 for face ID
  const [innerStep, setInnerStep] = useState(0); // For managing sub-steps
  const [user, setUser] = useState<User>();
  const [phone, setPhone] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const webcamRef = useRef<Webcam | null>(null)

  useEffect(() => {
    if (props.user && !user) setUser(props.user)
    console.log(`User changed: ${JSON.stringify(user, null, 2)}`)
    if (user?.phone_verified && user?.phone && user?.device_verified && user?.face_associated) {
      props.onRegister({ user: user });
    }
    if (user?.phone_verified && user?.phone && user?.device_verified && !user?.face_associated) {
      setCurrent(1); // Move to face ID registration
    }
    if (user && user?.phone && (!user?.phone_verified || !user?.device_verified)) {
      setInnerStep(1); // Move to phone verification step
    } else if (user && !user?.phone) {
      setInnerStep(0); // Ensure we are at the phone input step
    }
  }, [user, props.user]);

  const handleEnrollPhone = () => {
    if (phone) {
      setLoading(true);
      enrollPhone(phone, null, (data: any) => {
        window.heap.track('Enroll Phone Success', {user: JSON.stringify(data)})
        setUser(data);
        setLoading(false);
      }, (error: any) => {
        console.error(error);
        window.heap.track('Enroll Phone Error', {error: error})
        props.onError("An error occurred during phone enrollment. Please try again.");
        setLoading(false);
      });
    }
  };

  const handleCancel = useCallback(() => {
    const videoElem = webcamRef?.current?.video;
    if (videoElem && videoElem.srcObject) {
      const stream = videoElem.srcObject as MediaStream;
      stream.getTracks().forEach(track => track.stop());
    }
    props.setVisible(false);
  }, [props]);

  const handleRegister = useCallback((image: any) => {
    setLoading(true);
    UserService.enrollFace(JSON.stringify({
      'image': image,
      'deeplink': props.deeplink,
      'fingerprint': props.fingerprint,
      'verified_token': props.verifiedToken
    }), user?.access_token, null).subscribe(
        (data: any) => {
          window.heap.track('Enroll Face Success', {user: JSON.stringify(data?.user)})
          console.log(`User after enrollingFace: ${JSON.stringify(data?.user)}`)
          setUser(data?.user);
          setLoading(false);
          props.onRegister({ user: data?.user });
        },
        (error: any) => {
          setLoading(false);
          console.error(`Error enrolling face: ${error}`);
          window.heap.track('Enroll Face Error', {error: error})
          props.onError("An error occurred during face enrollment. Please try again.");
        }
    );
  }, [props, user]);

  const renderContent = () => {
    switch (current) {
      case 0: // Phone step
        return innerStep === 0 ? (
            <div style={{width: '100%'}}>
              <Row>
                <h2 style={{fontWeight: '600'}}>Register For YewPay</h2>
                <p>Register your phone and create your Face ID to pay and earn loyalty at the same time.</p>
              </Row>
              <PhoneNumberInput user={user} setPhone={setPhone} showHeader={false} />
              <Button type="primary" onClick={handleEnrollPhone} disabled={!phone || phone.length < 12 || loading} style={{width: '100%'}}>
                Next
              </Button>
            </div>
        ) : (
            <PhoneVerification user={user} setUser={setUser} onError={props.onError} fingerprint={props.fingerprint} header='Verify Phone Number'/>
        );
      case 1: // Face ID step
        return (
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <CameraPermissions />
              <div>
                <h2 style={{ fontWeight: '600' }}>Register Your Face ID</h2>
                <p>Create your secure Face ID with a single picture. You'll use your Face ID to log in and anonymously pay & use loyalty.</p>
              </div>
              {loading && <Spin size='large' />}
              {!loading && <EnrollFace handleImagesComplete={handleRegister} onError={props.onError} />}
            </div>
        );
      default:
        return null;
    }
  };

  return (
      <Modal
          open={props.visible}
          onCancel={handleCancel}
          footer={null}
          style={{ display: 'flex', flexDirection: 'column', top: 0, marginTop: '10px' }}
          bodyStyle={{ height: '100%', display: 'flex', flexDirection: 'column', marginTop: '20px', alignItems: 'center' }}
      >
        <Steps current={current} responsive={false} style={{width: '80%'}}>
          <Steps.Step title="Phone" icon={<PhoneOutlined />} />
          <Steps.Step title="Face ID" icon={<SmileOutlined />} />
        </Steps>
        {renderContent()}
      </Modal>
  );
};

export default RegisterAccountModal;

import React, {type FC} from 'react'
import {Col, Row, Typography} from 'antd'
import {formatDate} from "../utils/DateUtils";
import {EnrollVehicle} from '../models/EnrollVehicle';

interface EnrolledVehicleSummaryProps {
  vehicle: EnrollVehicle
}

const EnrolledVehicleSummary: FC<EnrolledVehicleSummaryProps> = (props) => {
  return (
      <div >
        <Row style={{ display: 'flex', alignItems: 'center' }}>
          <Typography style={{
            fontWeight: '600',
            textAlign: 'left'
          }}>
            Vehicle Under Review
          </Typography>
        </Row>
        <Row >
          <Col xs={14}>
            <Typography style={{ textAlign: 'left' }}>Added {formatDate(+props.vehicle?.date_created)}</Typography>
          </Col>
          <Col xs={10} style={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
                <Typography style={{ textAlign: 'right', color: 'red' }}>PENDING</Typography>
          </Col>
        </Row>

      </div>
  )
}

export default EnrolledVehicleSummary 

import React from 'react';
import {Col, Divider, Row, Typography} from 'antd';
import {LoyaltyBalance} from '../models/Loyalty';
import {StarFilled, StarOutlined} from '@ant-design/icons';

interface LoyaltyBalanceListComponentProps {
    loyaltyBalances: LoyaltyBalance[]
    goToLoyaltyDetail: (location_id: number) => void
}
const LoyaltyBalanceListComponent: React.FC<LoyaltyBalanceListComponentProps> = (props) => {
    const items = props.loyaltyBalances.map((balance: LoyaltyBalance, index: number) => {
        let redeemable = false;
        balance.redemption_tiers.forEach(tier => {
            if (balance.points_amount >= tier.points_threshold) {
                redeemable = true;
            }
        });
        return <>
            <Row key={index} onClick={() => { props.goToLoyaltyDetail(balance.location.id) }}>
                <Col span={18}>
                    <Typography>{balance.location.location_name}</Typography>
                </Col>
                <Col span={1}>
                    {balance.available_rewards.length > 0 && <StarFilled style={{ color: '#7ac968' }} />}
                    {balance.available_rewards.length == 0 && redeemable && <StarOutlined style={{ color: '#7ac968' }} />}
                </Col>
                <Col span={5} style={{ display: 'flex', flexDirection: 'column', alignItems: 'end' }}>
                    {balance.points_amount}pts
                </Col>
            </Row>
            {index < props.loyaltyBalances.length - 1 && <Divider style={{ marginTop: 8, marginBottom: 8 }} />}
        </>
    })
    return <>
        {items}
    </>
};

export default LoyaltyBalanceListComponent;
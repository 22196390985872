import React, {useEffect, useState} from 'react';
import {Card, Col, Divider, Row, Typography} from 'antd';
import {User} from '../models/User';
import {useNavigate} from 'react-router-dom'
import {Promo} from "../models/Promo";
import {LoyaltyService} from "../services/LoyaltyService";
import {LoyaltyRedemption} from "../models/Loyalty";

interface PerksPreviewProps {
    user: User
    onError: (description: string, title?: string) => void
    location_id?: any
    perks: any
    setPerks: (perks: any[]) => void
}
const PerksPreview: React.FC<PerksPreviewProps> = (props) => {
    const [loading, setLoading] = useState<boolean>(false)
    const navigate = useNavigate()

    useEffect(() => {
        const initialLoad = loading
        getPerks(initialLoad)
    }, [props.user])

    const getPerks = (initialLoad: boolean) => {
        LoyaltyService.getPerks(props.location_id, props.user.access_token, initialLoad ? setLoading : null).subscribe(
            (data: Promo[]) => {
                props.setPerks([...data])
            },
            (error: any) => {
                console.error(`Error getting perks ${error}`)
                props.onError(error)
            }
        )
    }

    let perksRender: any = props.perks.map((perk: LoyaltyRedemption, index: number) => {
        return <Row key={index}>
            <Col span={24}>
                <Typography style={{fontWeight: '600'}}>{perk.description}</Typography>
            </Col>
            {index < props.perks.length - 1 && <Divider />}
        </Row>
    })

    return (
        <Card style={{ overflowY: 'auto', textAlign: 'left' }} title={'Perks'} loading={loading}>
            {perksRender.length > 0 ? perksRender : "No perks available at this time"}
        </Card>
    );
};

export default PerksPreview;
import React, {useEffect, useState} from 'react';
import {Card, Col, Row, Typography} from 'antd';
import {User} from '../models/User';
import {LoyaltyBalance} from '../models/Loyalty';
import {useNavigate} from 'react-router-dom'
import LoyaltyBalanceListComponent from './LoyaltyBalanceListComponent';
import {LoyaltyService} from '../services/LoyaltyService';

interface LoyaltyPreviewProps {
    user: User
    onError: (description: string, title?: string) => void
}
const LoyaltyPreview: React.FC<LoyaltyPreviewProps> = (props) => {
    const [loading, setLoading] = useState<boolean>(false)
    const [loyaltyBalances, setLoyaltyBalances] = useState<LoyaltyBalance[]>([])
    const navigate = useNavigate()
    useEffect(() => {
        LoyaltyService.getLoyaltyBalances(0, 5, props.user.access_token, setLoading).subscribe(
            (data: LoyaltyBalance[]) => {
                setLoyaltyBalances(data)
            }, (error: any) => {
                console.error(error)
                props.onError(error)
            }
        )
    }, [props.user.access_token])
    const goToAllLoyalty = () => {
        navigate('/rewards/loyalty')
    }
    const goToLoyaltyDetail = (locationID: number) => {
        navigate(`/rewards/loyalty/${locationID}`)
    }
    return <Card style={{ width: '100%', textAlign: 'left' }} bodyStyle={{ paddingTop: 8 }} title={'Loyalty'} headStyle={{ marginBottom: 12 }} extra={<a onClick={goToAllLoyalty}>{loyaltyBalances.length > 3 ? 'View All' : ''}</a>} loading={loading}>
        <Row>
            <Col span={24}>
                {loyaltyBalances.length == 0 &&
                    <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Typography>Pay with YewPay to earn points today!</Typography>
                    </div>
                }
                <LoyaltyBalanceListComponent loyaltyBalances={loyaltyBalances} goToLoyaltyDetail={goToLoyaltyDetail} />
            </Col>
        </Row>
    </Card>
};

export default LoyaltyPreview;
import React, { useEffect, useState } from 'react';
import { Card, Col, Row, Typography } from 'antd';
import { User } from '../models/User';
import { LoyaltyBalance } from '../models/Loyalty';
import { useNavigate } from 'react-router-dom'
import LoyaltyBalanceListComponent from './LoyaltyBalanceListComponent';
import { LoyaltyService } from '../services/LoyaltyService';

interface LoyaltyScreenProps {
    user: User
    onError: (description: string, title?: string) => void
}
const LoyaltyScreen: React.FC<LoyaltyScreenProps> = (props) => {
    const [loading, setLoading] = useState<boolean>(false)
    const [loyaltyBalances, setLoyaltyBalance] = useState<LoyaltyBalance[]>([])
    const navigate = useNavigate()
    useEffect(() => {
        LoyaltyService.getLoyaltyBalances(0, 100000, props.user.access_token, setLoading).subscribe(
            (data: LoyaltyBalance[]) => {
                setLoyaltyBalance(data)
            }, (error: any) => {
                console.error(error)
                props.onError(error)
            }
        )
    }, [props.user.access_token])
    const goToLoyaltyDetail = (locationID: number) => {
        navigate(`/rewards/loyalty/${locationID}`)
    }

    return <div style={{ height: '100%', width: '100%', display: 'flex', flexDirection: 'column' }}>
        <Card style={{ height: '100%', width: '100%', textAlign: 'left' }} bodyStyle={{ paddingTop: 0 }} headStyle={{ minHeight: 0, marginTop: 12, marginBottom: 12 }} title={'Loyalty'} loading={loading}>
            <Row>
                <Col span={24}>
                    {loyaltyBalances.length == 0 &&
                        <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <Typography>Pay with YewPay to earn points today!</Typography>
                        </div>
                    }
                    <LoyaltyBalanceListComponent loyaltyBalances={loyaltyBalances} goToLoyaltyDetail={goToLoyaltyDetail} />
                </Col>
            </Row>
        </Card>
    </div>
};

export default LoyaltyScreen;
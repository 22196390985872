import React, {type FC, useEffect, useState} from 'react'
import {Col, Dropdown, MenuProps, Row, Typography} from 'antd'
import {useNavigate, useParams} from 'react-router-dom'
import {MoreOutlined} from '@ant-design/icons'
import ActivitySummaryComponent from '../home/ActivitySummaryComponent'
import {MechanismsService} from "../services/MechanismsService";
import {FaceMeta, Mechanism} from '../models/MechanismTypes'
import {User} from '../models/User'
import MechanismPaymentMethodComponent from "./MechanismPaymentMethodComponent";

interface FaceDetailScreenProps {
  user: User
  onError: (description: string, title?: string) => void
}

const FaceDetailScreen: FC<FaceDetailScreenProps> = (props) => {
  const navigate = useNavigate()
  const [mechanism, setMechanism] = useState<any>();
  const { id } = useParams()
  const [loading, setLoading] = useState<boolean>(false)
  const items: MenuProps['items'] = [
    {
      key: '1',
      label: (
        <a onClick={() => { changeStatus() }} rel={'noopener noreferrer'} >
          {mechanism?.status == 'DISABLED' ? 'Activate' : 'Disable'}
        </a>
      ),
    }
  ]

  const changeStatus = () => {
    let toStatus: string = mechanism?.status == 'DISABLED' ? 'ACTIVE' : 'DISABLED';
    mechanism!.status = toStatus;
    MechanismsService.updateStatus(JSON.stringify(mechanism), props.user.access_token, setLoading).subscribe((data: any) => {
      setMechanism(data)
    }, (error: any) => {
      console.error(`Error updating face status: ${error}`);
      props.onError(error, 'Error Updating Status')
    });
  }

  useEffect(() => {
    MechanismsService.getFace(props.user.access_token, setLoading).subscribe((data: Mechanism) => {
      setMechanism(data)
    }, (error: any) => {
      console.error(`Error fetching face: ${error}`);
      props.onError(error, 'Error Fetching Face Data')
    })
  }, [id, props.user])
  const meta: FaceMeta | undefined = mechanism?.meta as FaceMeta
  return (
      <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center'}}>
        <Col xs={24} sm={22} md={18} lg={12} style={{display: 'flex', flexDirection: 'column'}}>
          <Row>
            <Col span={20} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
              <Typography style={{ color: 'black', fontWeight: '600', fontSize: '18px' }}>
                Face
              </Typography>
              <span style={{marginLeft: '8px', marginRight: '8px'}}>{'\u00A0\u2022\u00A0'}</span>
              <Typography style={{color: mechanism?.status == 'ACTIVE' ? '#7ac968' : 'red', fontSize: '14px'}}>
                {mechanism?.status}
              </Typography>
            </Col>
            <Col span={4} style={{ display: 'flex', flexDirection: 'column', alignItems: 'end' }}>

              <Dropdown menu={{ items }} >
                <MoreOutlined style={{ fontSize: 20 }} />
              </Dropdown>

            </Col>
          </Row>
          <Row style={{marginTop: '10px'}}>
            <MechanismPaymentMethodComponent user={props.user} onError={props.onError} mechanism={mechanism} newCardRedirectRoute={'/identifiers/face'}/>
          </Row>
          <Row style={{ marginTop: '10px' }}>
            <Col span={24} style={{ width: '100%', height: '100%' }}>
              <ActivitySummaryComponent user={props.user} onError={props.onError} mechanism={mechanism} />
            </Col>
          </Row>
        </Col>
    </div >
  )
}
export default FaceDetailScreen

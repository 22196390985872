import React, {type FC, useRef, useState} from 'react'

import {Button} from 'antd'
import Webcam from 'react-webcam'
import {VideoConstraints} from '../videoConstants'

interface EnrollFaceProps {
  handleImagesComplete: (images: any) => void
  onError: (description: string, title?: string) => void

}

const EnrollFace: FC<EnrollFaceProps> = (props) => {
  const [buttonDisabled, setDisableButton] = useState(false)

  const webcamRef = useRef<Webcam>(null)

  const takePhoto = (): void => {
    if (webcamRef.current == null) {
      return
    }
    setDisableButton(true)
    const imageSrc = webcamRef.current.getScreenshot()
    props.handleImagesComplete(imageSrc)
  }

  return <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
    <Webcam
      audio={false}
      key={Date.now()}
      ref={webcamRef}
      style={{ width: '100%', objectFit: 'cover' }}
      videoConstraints={VideoConstraints}
      onUserMediaError={error => { 
        console.error('Webcam Error: ', error) 
        props.onError(error.toString(), 'Webcam Error')
      }}
    />
    <Button type='primary' block onClick={takePhoto} disabled={buttonDisabled} style={{ marginTop: '20px', height: '40px' }}>Take Photo</Button>
  </div>
}

export default EnrollFace

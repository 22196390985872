import React, {type FC, useEffect} from 'react'
import {Button, Col, Row, Typography} from 'antd'
import ActivitySummaryComponent from './ActivitySummaryComponent'
import {useNavigate} from 'react-router-dom'
import {User} from '../models/User'
import PendingVehicles from '../tasks/PendingVehicles'
import {Mechanism} from '../models/MechanismTypes'
import Refer from './Refer'
import styles from './HomeComponent.module.css';
import {UserOutlined} from '@ant-design/icons'
import MapComponent from "./MapComponent";

interface HomeComponentProps {
    user: User
    onError: (description: string, title?: string) => void
    refreshUser: () => void
    showInstall: () => void
    tourRefs: any
    showTour: () => void
}

const HomeComponent: FC<HomeComponentProps> = (props) => {
    useEffect(() => {
        props.refreshUser()
    }, [])
    const navigate = useNavigate()
    const goToPaymentMethods = () => {
        navigate('/cards')
    }

    const goToPaymentMechanisms = () => {
        navigate('/identifiers')
    }
    const goToProfile = () => {
        navigate('/profile')
    }
    const goToVehicle = (vehicle: Mechanism): void => {
        navigate(`/identifiers/vehicles/${vehicle.meta.id}`)
    }
    useEffect(() => {
        props.showInstall()
        props.showTour()
    }, [])

    return (
        <div className={styles.noScrollbar} style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', overflow: 'auto', alignItems: 'center' }}>
            <Col xs={24} sm={20} md={18} lg={12} style={{display: 'flex', flexDirection: 'column'}}>
                <Row style={{width: '100%'}}>
                    <Col span={20} style={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
                        <Typography style={{ color: 'black', fontWeight: '600', fontSize: '18px' }}>Welcome back!</Typography>
                    </Col>
                    <Col span={4} style={{ display: 'flex', flexDirection: 'column', alignItems: 'end' }}>
                        <Button icon={<UserOutlined />} shape='circle' onClick={goToProfile} ref={props.tourRefs?.profileRef}></Button>
                    </Col>
                </Row>
                <PendingVehicles user={props.user} onError={props.onError} goToAllPendingVehicles={goToPaymentMechanisms} goToVehicle={goToVehicle} limit={3} />
                {/*<Row style={{ marginTop: '10px' }}>*/}
                {/*    <WaitlistComponent user={props.user} onError={props.onError} waitlistType={'wallet'} />*/}
                {/*</Row>*/}
                <Row style={{ marginTop: '10px' }}>
                    <Refer user={props.user} onError={props.onError} />
                </Row>
                <Row style={{ marginTop: '10px'}}>
                    <MapComponent user={props.user} onError={props.onError}/>
                </Row>
                <Row style={{ marginTop: '10px' }}>
                    <ActivitySummaryComponent user={props.user} onError={props.onError} />
                </Row>
            </Col>
        </div>
    )
}

export default HomeComponent

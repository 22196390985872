import React, {type FC, useEffect, useState} from 'react'
import {Col, Row, Typography} from 'antd'
import {MoreOutlined} from '@ant-design/icons'
import {useNavigate, useParams} from 'react-router-dom'
import {formatCurrency} from '../utils/NumberUtils'
import {TransactionsService} from '../services/TransactionsService'
import {User} from '../models/User'
import {Transaction} from '../models/TransactionTypes'
import {VehicleMeta} from '../models/MechanismTypes'
import {formatDateTimeShort} from "../utils/DateUtils";

interface TransactionProps {
    user: User
    onError: (description: string, title?: string) => void
}

const TransactionDetailScreen: FC<TransactionProps> = (props) => {
    const { id } = useParams()
    const [transaction, setTransaction] = useState<Transaction>()
    const [loading, setLoading] = useState<boolean>(false)
    const [discounts, setDiscounts] = useState<any>([])
    const navigate = useNavigate()

    useEffect(() => {
        TransactionsService.getTransactionByID(id, props.user.access_token, setLoading).subscribe((data: Transaction) => {
            setTransaction(data)
        }, (error: any) => {
            console.error(`Error fetching transactions: ${error}`);
            props.onError(error, `Error Fetching Transactions`);
})
    }, [props.user])

    useEffect(() => {
        const original_amount = transaction?.payment?.meta?.data?.object?.metadata?.original_amount || 0
        const loyalty_discount = transaction?.payment?.meta?.data?.object?.metadata?.loyalty_discount || 0
        const promo_discount = transaction?.payment?.meta?.data?.object?.metadata?.promo_discount || 0
        const credit_discount = transaction?.payment?.meta?.data?.object?.metadata?.credit_discount || 0
        const tmp = []
        tmp.push(['Original Amount', original_amount])
        if (loyalty_discount > 0) {
            tmp.push(['Loyalty Discount', loyalty_discount*-1])
        }
        if (promo_discount > 0) {
            tmp.push(['Promo Discount', promo_discount*-1])
        }
        if (credit_discount > 0) {
            tmp.push(['Credit Applied', credit_discount*-1])
        }
        setDiscounts([...tmp])
    }, [transaction])

    const goToLocation = () => {
        if (transaction) {
            navigate(`/rewards/loyalty/${transaction.location?.id}`)

        }
    }

    const goToIdentifier = () => {
        if (transaction) {
            if (transaction?.mechanism?.mechanism_type == 'FACE') {
                navigate(`/identifiers/face`)
            } else {
                navigate(`/identifiers/vehicles/${transaction.mechanism.mechanism_id}`)
            }

        }
    }

    const goToPaymentMethod = () => {
        if (transaction) {
            navigate(`/cards/${transaction.payment_method.id}`)

        }
    }

    let identifier = ""
    switch (transaction?.mechanism.mechanism_type) {
        case 'VEHICLE':
            const plate = (transaction?.mechanism.meta as VehicleMeta)?.plate
            identifier = plate?.substring(plate?.length - 3)
            break;
        default:
            break;
    }
    return (
        <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center'}}>
            <Col xs={24} sm={22} md={18} lg={12} style={{display: 'flex', flexDirection: 'column'}}>
                <Row>
                    <Col span={20} style={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
                        <Typography style={{ color: 'black', fontWeight: '600', fontSize: '18px' }}>Transaction Detail</Typography>
                    </Col>
                    <Col span={4} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                        <MoreOutlined style={{ fontSize: '20px' }} />
                    </Col>
                </Row>
                <Row style={{ marginTop: '30px' }}>
                    <Col span={24}>
                        <Row style={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
                            <Col span={24}>
                                <Typography style={{ fontWeight: '600', color: 'black' }}>Location</Typography>
                            </Col>
                            <Col span={24}>
                                <Typography style={{ color: 'black' }}><a onClick={goToLocation}>{transaction?.location.location_name}</a></Typography>
                            </Col>
                            <Col span={24}>
                                <Typography style={{ color: 'black' }}>{transaction?.location.addr}</Typography>
                            </Col>
                            <Col span={24}>
                                <Typography style={{ color: 'black' }}>{transaction?.location.city}, {transaction?.location.state} {transaction?.location.zipcode}</Typography>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row style={{ marginTop: '30px' }}>
                    <Col span={24}>
                        <Row style={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
                            <Col span={24}>
                                <Typography style={{ fontWeight: '600', color: 'black' }}>Identifier</Typography>
                            </Col>
                            <Col span={24}>
                                {transaction?.mechanism?.mechanism_type == 'VEHICLE' &&
                                    <Typography style={{ color: 'black' }}><a onClick={goToIdentifier}>{transaction?.mechanism.nickname || transaction?.mechanism.mechanism_type} (...{identifier})</a></Typography>
                                }
                                {transaction?.mechanism?.mechanism_type == 'FACE' &&
                                    <Typography style={{ color: 'black' }}><a onClick={goToIdentifier}>Face</a></Typography>
                                }
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row style={{ marginTop: '30px' }}>
                    <Col span={24}>
                        <Row style={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
                            <Col span={24}>
                                <Typography style={{ fontWeight: '600', color: 'black' }}>Payment Method</Typography>
                            </Col>
                            <Col span={24}>
                                <Typography style={{ color: 'black' }}><a onClick={goToPaymentMethod}>{transaction?.payment_method.nickname} (...{transaction?.payment_method.meta.card.last4})</a></Typography>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row style={{ marginTop: '30px' }}>
                    <Col span={24}>
                        <Row style={{ display: 'flex', flexDirection: 'column', alignItems: 'start'}}>
                            <Col span={24}>
                                <Typography style={{ fontWeight: '600', color: 'black' }}>Details</Typography>
                            </Col>
                            <Col span={24} style={{width: '100%'}}>
                                <Row style={{width: '100%'}}>
                                    <Col span={8}><Typography style={{textAlign: 'left'}}>Date</Typography></Col>
                                    <Col span={16}><Typography style={{textAlign: 'right'}}>{transaction?.payment.date_created && formatDateTimeShort(transaction?.payment.date_created)}</Typography></Col>
                                </Row>
                            </Col>
                            {discounts?.length > 0 && discounts.map((discount: any, index: number) => {
                                return (<Col span={24} style={{width: '100%'}}>
                                    <Row style={{width: '100%'}}>
                                        <Col span={16}><Typography style={{textAlign: 'left', color: discount[1] < 0 ? 'red' : 'black' }}>{discount[0]}</Typography></Col>
                                        <Col span={8}><Typography style={{textAlign: 'right', color: discount[1] < 0 ? 'red' : 'black'}}>{formatCurrency(discount[1])}</Typography></Col>
                                    </Row>
                                </Col>)
                            })}
                            <Col span={24} style={{width: '100%'}}>
                                <Row style={{width: '100%'}}>
                                    <Col span={16}><Typography style={{textAlign: 'left'}}>Amount Charged</Typography></Col>
                                    <Col span={8}><Typography style={{textAlign: 'right'}}>{transaction?.payment.amount && formatCurrency(transaction?.payment.amount)}</Typography></Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Col>
        </div>

    )
}

export default TransactionDetailScreen

import React, {FC, useState} from 'react';
import PhoneInput from 'react-phone-input-2';
import {User} from '../models/User';
import {UserService} from '../services/UserService';
import 'react-phone-input-2/lib/style.css'
import './AuthComponent.css';

interface PhoneNumberInputProps {
    user: User | undefined
    setPhone: any
    showHeader: boolean
    padding?: number
}
const PhoneNumberInput: FC<PhoneNumberInputProps> = (props) => {
    const [phoneNumber, setPhoneNumber] = useState('');

    const handlePhoneChange = (value: any) => {
        setPhoneNumber(value);
        props.setPhone(`+${value}`)
    };

    const padding = props.padding === undefined ? 20 : props.padding;

    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: padding}}>
            {props.showHeader &&
                <h2 style={{ fontWeight: '600' }}>Register Phone</h2>

            }
            <PhoneInput
                country={'us'}
                onlyCountries={['us']}
                value={phoneNumber}
                onChange={handlePhoneChange}
                specialLabel=''
                inputClass="ant-input ant-input-md phone-number-input"
                countryCodeEditable={false}
                copyNumbersOnly={false}
            />
        </div>
    );
};

const registerPhone = (phone: string, user: User, loadingHandler: any, onSuccess: any, onError: any) => {
    const copiedUser = { ...user }
    copiedUser.phone = phone
    UserService.updatePhone(
        JSON.stringify(copiedUser), copiedUser.access_token, loadingHandler
    ).subscribe(
        (new_user: User) => {
         onSuccess(new_user)
        }, onError)

}

const enrollPhone = (phone: string, loadingHandler: any, onSuccess: any, onError: any) => {
    UserService.enrollPhone(
        JSON.stringify({phone}), loadingHandler
    ).subscribe(
        (new_user: User) => {
         onSuccess(new_user)
        }, onError)

}



export { PhoneNumberInput, registerPhone, enrollPhone };

import React, {type FC} from 'react'
import {Col, Row, Typography} from 'antd'
import {formatDate} from '../utils/DateUtils'
import {User} from '../models/User'
import {PaymentMethod} from '../models/PaymentMethodTypes'

interface PaymentMethodSummaryProps {
  user: User
  paymentInfo: PaymentMethod
  onClick: any
}

const PaymentMethodSummary: FC<PaymentMethodSummaryProps> = (props) => {
  return (
    <div onClick={() => props.onClick(props.paymentInfo)}>
        <Row>
            <Col xs={14}>
                <Row style={{ display: 'flex', alignItems: 'center' }}>
                    <Typography style={{
                        fontWeight: '600',
                        textAlign: 'left'
                    }}>
                        {props.paymentInfo.nickname} (...{props.paymentInfo.meta.card.last4})
                    </Typography>
                </Row>
                <Row >
                    <Col xs={24}>
                        <Typography style={{ textAlign: 'left' }}>Added {formatDate(props.paymentInfo.date_created)}</Typography>
                        {/* <Typography style={{ textAlign: 'left' }}>Last Used {formatDateTime(props.paymentInfo.date_last_used)}</Typography> */}
                    </Col>
                </Row>
            </Col>
            <Col xs={10} style={{display: 'flex', justifyContent: 'end', alignItems: 'center'}}>
                <Typography style={{ textAlign: 'right', color: props.paymentInfo.status == 'ACTIVE' ? 'green' : 'red'}}>{props.paymentInfo.status}</Typography>
            </Col>
        </Row>

    </div>
  )
}

export default PaymentMethodSummary

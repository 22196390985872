import React, {type FC} from 'react'
import {Col, Row, Space, Typography} from 'antd'
import {useLocation, useNavigate} from 'react-router-dom'
import {User} from '../models/User'
import {loadStripe} from "@stripe/stripe-js";
import {Elements} from '@stripe/react-stripe-js';
import AddPaymentMethodForm from "./AddPaymentMethodForm";

interface AddCardScreenProps {
  user: User
  onError: (description: string, title?: string) => void
}
const useQuery = () => {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}
const AddCardScreen: FC<AddCardScreenProps> = (props) => {
  const navigate = useNavigate();
    const query = useQuery();
    const location = useLocation();
    const state = location.state;
    // const stripePromise = loadStripe('pk_test_51N207wKTwpny3PXDugvr5CC2G8YQ3vLihyWgsuFb5P9KrTatn1KV1JA4eBKdUVx12T2bIRm9WtMZ86kenDwpr7km00tRON5xC5');
    const stripePromise = loadStripe('pk_live_51N207wKTwpny3PXDftkBEJNpZ4WQ4oHHFmkj72Q0yN1mTvDDQuJhNiFBN63D26zosOnH8pim5oZFJ2vXHHOVIO0x00tI8gregz');
    const options: any = {
        // Fully customizable with appearance API.
        mode: 'setup',
        currency: 'usd',
        setupFutureUsage: 'off_session',
        paymentMethodTypes: ['card'],
        appearance: {/*...*/},
    };

    console.log(`redirectRoute: ${state?.redirectRoute}`)


  return (
      <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center'}}>
          <Col xs={24} sm={22} md={18} lg={12} style={{display: 'flex', flexDirection: 'column'}}>
              <Row>
                <Col span={24} style={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
                  <Typography style={{ color: 'black', fontWeight: '600', fontSize: '18px' }}>Add Card</Typography>
                </Col>
              </Row>
              <Space />
              <Row style={{ flex: 1, overflow: 'auto', marginTop: '20px' }}>
                <Col span={24} style={{ width: '100%', height: '100%' }}>
                    <Elements stripe={stripePromise} options={options}>
                    <AddPaymentMethodForm user={props.user} onError={props.onError} redirectRoute={state?.redirectRoute}/>
                    </Elements>
                </Col>
              </Row>
          </Col>
    </div>
  )
}
export default AddCardScreen;

import GeneralService from './GeneralService'
import {Component} from 'react'

const PAYMENTS_ENDPOINT = "https://api.yewfi.com"
export class PaymentMethodsService extends Component {
  public static getCards(offset: number, limit: number, accessToken: any, loadingHandler: any) {
    return GeneralService.get(`/payment_methods/cards/${offset}/${limit}`, accessToken, loadingHandler, JSON)
  }
  public static getCardById(id: any, accessToken: any, loadingHandler: any) {
    return GeneralService.get(`/payment_methods/cards/${id}`, accessToken, loadingHandler, JSON)
  }
  public static getCardByStripeId(stripe_id: any, accessToken: any, loadingHandler: any) {
    return GeneralService.get(`/payment_methods/cards/stripe_id/${stripe_id}`, accessToken, loadingHandler, JSON)
  }
  public static renameCard(paymentMethod: any, accessToken: any, loadingHandler: any) {
    return GeneralService.put(`/payment_methods/cards/update/nickname`, paymentMethod, accessToken, loadingHandler, JSON)
  }
  public static updateCardStatus(paymentMethod: any, accessToken: any, loadingHandler: any) {
    return GeneralService.put(`/payment_methods/cards/update/status`, paymentMethod, accessToken, loadingHandler, JSON)
  }
  public static deleteCard(paymentMethod: any, accessToken: any, loadingHandler: any) {
    return GeneralService.delete(`/payment_methods/cards`, paymentMethod, accessToken, loadingHandler, JSON)
  }
  public static getSetupIntent(accessToken: any, loadingHandler: any) {
    return GeneralService.get(`/payments/setup_intent`, accessToken, loadingHandler, JSON, PAYMENTS_ENDPOINT)
  }
  public static getMechanismPaymentMethod(mechanism_id: any, accessToken: any, loadingHandler: any) {
    return GeneralService.get(`/payment_methods/mechanism/${mechanism_id}`, accessToken, loadingHandler, JSON)
  }
  public static getMechanismEligiblePaymentMethods(mechanism_id: number, offset: number, limit: number, accessToken: any, loadingHandler: any) {
    return GeneralService.get(`/payment_methods/mechanism/${mechanism_id}/cards/${offset}/${limit}`, accessToken, loadingHandler, JSON)
  }
  public static setMechanismPaymentMethod(mechanism_id: number, paymentMethod: any, accessToken: any, loadingHandler: any) {
    return GeneralService.put(`/payment_methods/mechanism/${mechanism_id}`, paymentMethod, accessToken, loadingHandler, JSON)
  }
}
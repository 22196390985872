export function formatCurrency (amount: number) {
  return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(amount / 100)
}

export function formatCoordinates(coords: string) {
  const parts = coords.replace('(', '').replace(')', '').split(',');

  // Parse the latitude and longitude as floats
  const lng = parseFloat(parts[1].trim());
  const lat = parseFloat(parts[0].trim());

  return {lat, lng};
}

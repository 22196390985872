import GeneralService from './GeneralService'
import { Component } from 'react'

export class NotificationSettingsService extends Component {
  public static updateNotificationSetting(notificationSetting: any, accessToken: any, loadingHandler: any) {
    return GeneralService.put(`/notifications/settings/update`, notificationSetting, accessToken, loadingHandler, JSON)
  }
  public static getNotificationSettings(accessToken: any, loadingHandler: any) {
    return GeneralService.get(`/notifications/settings`, accessToken, loadingHandler, JSON)
  }
}
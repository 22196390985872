import React, {type FC, useEffect, useState} from 'react'
import {Card, Col, Rate, Row, Typography} from 'antd'
import {User} from '../models/User'
import {LoyaltyActivity, LoyaltyBalance} from '../models/Loyalty'
import {useNavigate, useParams} from 'react-router-dom'
import LoyaltyRedemptionTiersComponent from './LoyaltyRedemptionTiersComponent'
import {LoyaltyService} from '../services/LoyaltyService'
import LoyaltyActivityListComponent from './LoyaltyActivityListComponent'
import PromosPreview from "./PromosPreview";
import {Promo} from "../models/Promo";
import MerchantMapComponent from "./MerchantMapComponent";
import {LocationService} from "../services/LocationService";
import {Location} from '../models/LocationTypes';
import PerksPreview from "./PerksPreview";
import {roundHalf, titleCaseSentence} from "../utils/MiscUtils";

const API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY as string;

interface LoyaltyDetailScreenProps {
  user: User
  onError: (description: string, title?: string) => void
}

const LoyaltyDetailScreen: FC<LoyaltyDetailScreenProps> = (props) => {
  const [loyaltyActivity, setLoyaltyActivity] = useState<LoyaltyActivity[]>([])
  const [loyaltyBalance, setLoyaltyBalance] = useState<LoyaltyBalance>()
  const [redeemCount, setRedeemCount] = useState<number>(0)
  const [loyaltyBalanceLoading, setLoyaltyBalanceLoading] = useState<boolean>(false)
  const [loyaltyActivityLoading, setLoyaltyActivityLoading] = useState<boolean>(false)
  const [promos, setPromos] = useState<Promo[]>([])
  const [perks, setPerks] = useState<Promo[]>([])
  const [loc, setLoc] = useState<Location | null>(null);
  const [locLoading, setLocLoading] = useState<boolean>(true);
  const [locationDetail, setLocationDetail] = useState<any>(null);
  const { id } = useParams()

  useEffect(() => {
    LoyaltyService.getLoyaltyBalanceByLocation(id, props.user.access_token, redeemCount == 0 ? setLoyaltyBalanceLoading : null).subscribe(
      (data: LoyaltyBalance) => {
        setLoyaltyBalance(data)

      }, (error: any) => {
        console.error(error)
        props.onError(error)
      }
    )
    LoyaltyService.getLoyaltyActivityByLocation(id, 0, 5, props.user.access_token, redeemCount == 0 ? setLoyaltyActivityLoading : null).subscribe(
      (data: LoyaltyActivity[]) => {
        setLoyaltyActivity(data)

      }, (error: any) => {
        console.error(error)
        props.onError(error)
      }
    )
  }, [props.user, redeemCount])

  useEffect(() => {
    LocationService.getLocation(id, props.user.access_token, setLocLoading).subscribe((data: Location) => {
      setLoc(data);
    }, (error: any) => {
      console.error(error)
      props.onError(error)
    });
  }, [props.user]);

  useEffect(() => {
    if (loc?.place_id && !locationDetail) {
      fetchLocationDetail()
    }
  }, [loc])

  const fetchLocationDetail = async () => {
    LocationService.getLocationDetails(loc?.place_id, props.user.access_token, null).subscribe((data: any) => {
      setLocationDetail(data)
    }, (error: any) => {
      console.error(error)
      props.onError(error)
      throw error;
    });
  }

  const activateLoyalty = (redemptionID: number) => {
    LoyaltyService.activateLoyaltyReward(redemptionID, props.user.access_token, null).subscribe((data: any) => {
      window.heap.track('Reward Activate Success', {redemptionId: redemptionID})
      setRedeemCount(redeemCount + 1)
    }, (error: any) => {
      console.error(error);
      window.heap.track('Reward Activate Error', {redemptionId: redemptionID, error: error})
      props.onError(error, 'Error Activating Reward')
    })
  }
  const navigate = useNavigate()
  const goToAllLoyaltyActivity = () => {
    navigate(`/rewards/loyalty/${loyaltyBalance?.location.id}/activity`)
  }
  return (
      <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center'}}>
        <Col xs={24} sm={22} md={18} lg={12} style={{display: 'flex', flexDirection: 'column'}}>
          <Row>
            <Col span={24} style={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
              <Typography style={{ color: 'black', fontWeight: '600', fontSize: '18px' }}>{loyaltyBalance?.location.location_name}</Typography>
            </Col>
            {locationDetail &&
                <Col span={24} style={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
                  <Row style={{justifyItems: 'center'}}>
                    <span>{locationDetail.rating}</span>
                    <Rate disabled allowHalf defaultValue={roundHalf(locationDetail.rating)} style={{fontSize: '12px', marginLeft: '8px'}} />
                    <span style={{marginLeft: '8px'}}>({locationDetail.user_ratings_total})</span>
                  </Row>
                  <Row>
                    <Typography>{titleCaseSentence(locationDetail.types[0])}</Typography>
                  </Row>
                </Col>
            }
          </Row>
          <Row style={{overflow: 'auto', marginTop: '20px' }}>
            {loc != null &&
                <Col span={24}>
                  <Row style={{}}>
                    <MerchantMapComponent user={props.user} onError={props.onError} location={loc}/>
                  </Row>
                </Col>
            }
            <Col span={24} style={{width: '100%', marginTop: '20px', display: promos.length > 0 ? 'block' : 'none'}}>
              <Row>
                <Col span={24} style={{ width: '100%', overflowY: 'auto' }}>
                  <PromosPreview user={props.user} onError={props.onError} location_id={id} promos={promos} setPromos={setPromos}/>
                </Col>
              </Row>
            </Col>
            <Col span={24} style={{ width: '100%', marginTop: '20px'}}>
              <LoyaltyRedemptionTiersComponent activateLoyalty={activateLoyalty} loyaltyBalance={loyaltyBalance} loyaltyBalanceLoading={loyaltyBalanceLoading}/>
            </Col>
            <Col span={24} style={{width: '100%', marginTop: '20px', display: perks.length > 0 ? 'block' : 'none'}}>
              <Row>
                <Col span={24} style={{ width: '100%', overflowY: 'auto' }}>
                  <PerksPreview user={props.user} onError={props.onError} location_id={id} perks={perks} setPerks={setPerks}/>
                </Col>
              </Row>
            </Col>
            <Col span={24} style={{ width: '100%', marginTop: '20px' }}>
              <Card style={{ height: 'auto', overflowY: 'auto', textAlign: 'left' }} title={'Activity'} loading={loyaltyActivityLoading} extra={<a onClick={goToAllLoyaltyActivity}>{loyaltyActivity.length > 4 ? 'View All' : ''}</a>}>
                <LoyaltyActivityListComponent loyaltyActivity={loyaltyActivity.slice(0, 4)} />
              </Card>
            </Col>
          </Row>
        </Col>
    </div>
  )
}
export default LoyaltyDetailScreen

import {FC, useMemo, useState} from "react";
import {InfoWindow, Map, Marker, useMap} from '@vis.gl/react-google-maps';
import styles from './MerchantMapComponent.module.css';
import {MapCameraChangedEvent} from "@vis.gl/react-google-maps/dist/components/map/use-map-events";
import {User} from "../models/User";
import {Location} from "../models/LocationTypes";
import {formatCoordinates} from "../utils/NumberUtils";
import './MerchantMapComponent.css';
import {FloatButton, Row, Typography} from "antd";
import {AimOutlined, InfoCircleOutlined, StarOutlined} from "@ant-design/icons";

interface MerchantMapComponentProps {
    user: User
    onError: (description: string, title?: string) => void
    location: Location
}

const MerchantMapComponent: FC<MerchantMapComponentProps> = (props) => {

    // State for user's current location
    const [loading, setLoading] = useState(false);
    const [selectedLocation, setSelectedLocation] = useState<any>(null);
    const map = useMap();
    const mapStyles = [
        {
            "featureType": "poi",
            "stylers": [
                { "visibility": "off" }
            ]
        }
    ]

    // useMemo to memoize InfoWindow content based on selectedLocation
    const infoWindowContent = useMemo(() => {
        if (!selectedLocation) return null;

        return (
            <div>
                <Row>
                    <Typography style={{fontWeight: '600', fontSize: 12}}>
                        {selectedLocation.location_name}
                    </Typography>
                </Row>
                <Row>
                    <Typography style={{fontSize: 12}}>
                        {selectedLocation.addr}
                    </Typography>
                </Row>
                {selectedLocation.loyalty_description && selectedLocation.loyalty_description.length > 0 &&
                    <Row style={{marginTop: '8px'}}>
                        <StarOutlined style={{color: '#7ac968'}}/>
                        <Typography
                            style={{marginLeft: '6px', fontWeight: '600', fontSize: 12, color: '#7ac968'}}>
                            {selectedLocation.loyalty_description}
                        </Typography>
                    </Row>
                }
                {selectedLocation.loyalty_perk && selectedLocation.loyalty_perk.length > 0 &&
                    <Row>
                        <Typography
                            style={{fontWeight: '600', fontSize: 12, color: '#7ac968'}}>
                            {selectedLocation.loyalty_perk}
                        </Typography>
                    </Row>
                }
                {selectedLocation.url && selectedLocation.url.length > 0 &&
                    <Row style={{display: 'flex', justifyContent: 'end', marginTop: '8px'}}>
                        <Typography.Link href={selectedLocation.url} target={'_blank'}
                                         style={{fontSize: '12px'}}>
                            More Info <InfoCircleOutlined/>
                        </Typography.Link>
                    </Row>
                }
            </div>
        );
    }, [selectedLocation]); // Depend on selectedLocation and loading state


    function debounce<T extends (...args: any[]) => void>(func: T, wait: number): (...args: Parameters<T>) => void {
        let timeout: NodeJS.Timeout | null = null;

        return function(...args: Parameters<T>): void {
            const later = () => {
                timeout = null;
                func(...args);
            };

            if (timeout) {
                clearTimeout(timeout);
            }
            timeout = setTimeout(later, wait);
        };
    }

    const handleBoundsChange = (event: MapCameraChangedEvent) => {
        const bounds = event.map.getBounds();
        // event.detail {"center":{"lat":37.59144890000001,"lng":-122.06294000000001},"zoom":11,"heading":0,"tilt":0,"bounds":{"south":37.49617319741868,"west":-122.1831029638672,"north":37.686602787496504,"east":-121.94277703613282}}
    };

    const debouncedHandleBoundsChange = debounce(handleBoundsChange, 300);

    const onMarkerClick = (event: google.maps.MapMouseEvent, index: number, location: Location) => {
        event.stop();
        setSelectedLocation(location);
    }

    const closeInfoWindow = () => {
        setSelectedLocation(null);
    }

    const recenter = () => {
        map?.setCenter(formatCoordinates(props.location.coordinates));
        map?.setZoom(11);
    }

    return (
        <div className={styles.mapWrapper}>
            <FloatButton className={styles.recenterButton} onClick={() => recenter()} icon={<AimOutlined />}></FloatButton>
            <div className={styles.map}>
                <Map
                    zoom={11}
                    center={formatCoordinates(props.location.coordinates)}
                    gestureHandling={'greedy'}
                    disableDefaultUI={true}
                    onBoundsChanged={debouncedHandleBoundsChange}
                    keyboardShortcuts={false}
                    clickableIcons={false}
                    maxZoom={18}
                    minZoom={9}
                    onClick={() => closeInfoWindow()}
                    styles={mapStyles}
                >
                    <Marker position={formatCoordinates(props.location.coordinates)} onClick={(event) => onMarkerClick(event, 0, props.location)}
                            icon={{
                                url: (require(props.location.status == 'ACTIVE' ? '../assets/marker_positive.svg' : '../assets/marker_negative.svg').default),
                                fillColor: '#ffffff',
                                scaledSize: props.location.status == 'ACTIVE' ? new google.maps.Size(20, 33) : new google.maps.Size(15, 25)
                            }} />;
                    {selectedLocation !== null &&
                        <InfoWindow position={formatCoordinates(selectedLocation.coordinates)} onCloseClick={closeInfoWindow} maxWidth={300} minWidth={250}>
                            {infoWindowContent}
                        </InfoWindow>
                    }
                </Map>
            </div>
        </div>
    );
};

export default MerchantMapComponent;
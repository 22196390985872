import React, {type FC, useCallback, useEffect, useState} from 'react'
import {Button, Card, Col, Row, Switch, Typography} from 'antd'
import {CheckOutlined, MailOutlined, MessageOutlined, PhoneOutlined} from '@ant-design/icons'
import {useNavigate} from 'react-router-dom'
import {User} from '../models/User'
import {type NotificationSetting} from '../models/NotificationSetting'
import {NotificationSettingsService} from '../services/NotificationSettingsService'
import {UserService} from '../services/UserService'
import ContactSupportModal from "./ContactSupportModal";

interface ProfileScreenProps {
    user: User
    handleUserLogout: () => void
    onError: (description: string, title?: string) => void
}

const ProfileScreen: FC<ProfileScreenProps> = (props) => {
    const [notificationSettings, setNotificationSettings] = useState<NotificationSetting[]>([])
    const [settingChange, setSettingChange] = useState<number>(0)
    const [fullName, setFullName] = useState<string>(props.user.full_name)
    const [notificationLoading, setNotificationLoading] = useState<boolean>(true)
    const [email, setEmail] = useState<string>(props.user.email)
    const [user, setUser] = useState<User>(props.user)
    const namePlaceholder = 'Your Name';
    const emailPlaceholder = 'your@email.com';
    const navigate = useNavigate()
    const [contactSupportModalOpen, setContactSupportModalOpen] = useState(false);
    const refreshUser = useCallback(() => {
        UserService.refreshUser('', props.user.access_token, null).subscribe((user: User) => {
            setUser(user)
            setEmail(user.email)
            setFullName(user.full_name)
        }, (error: any) => {
            console.error(error)
            window.heap.track('user_refresh_error', { 'message': error });
        })
    }, [props.user])
    useEffect(() => {
        refreshUser()
    }, [])
    useEffect(() => {
        NotificationSettingsService.getNotificationSettings(props.user.access_token, settingChange > 0 ? null : setNotificationLoading).subscribe(
            (data: NotificationSetting[]) => {
                setNotificationSettings(data)
            },
            (error: any) => {
                console.error(`Error getting notification setings ${error}`)
                props.onError(error)
            }
        )
    }, [props.user.access_token, settingChange])

    useEffect(() => {
        if (email == user.email && fullName == user.full_name) {
            return
        }
        // Update user props without overwriting token
        UserService.updateUser(JSON.stringify({
            email: email != emailPlaceholder ? email : "",
            full_name: fullName != namePlaceholder ? fullName : ""
        }), props.user.access_token, null).subscribe((user: User) => {
            refreshUser()
        }, (error: any) => {
            console.error(`Error updating user: ${error}`)
            props.onError(error)
        })
    }, [email, fullName])

    const settingsMap = new Map<string, Map<string, boolean>>()
    notificationSettings.forEach((element: NotificationSetting) => {
        if (!settingsMap.has(element.notification_type)) {
            settingsMap.set(element.notification_type, new Map())
        }
        settingsMap.get(element.notification_type)?.set(element.communication_method, element.enabled)
    });

    const openContactSupportModal = () => {
        setContactSupportModalOpen(true);
    }


    const changeSettings = (notificationSetting: NotificationSetting) => {
        NotificationSettingsService.updateNotificationSetting(JSON.stringify(notificationSetting), props.user.access_token, null).subscribe(
            (data: NotificationSetting[]) => {
                setSettingChange(settingChange + 1)
            },
            (error: any) => {
                console.error(`Error getting notification settings ${error}`)
                window.heap.track('notification_settings_error', { 'message': error });
            }
        )

    }

    const buildRow = useCallback((notificationType: string) => {
        const textMap: any = {
            "RECEIPT": "Receipt",
            "LOGIN": "Login",
            "DEALS": "Deals",
        }
        return <Row style={{ marginTop: '10px' }}>
            <Col span={9}>
                <Typography>
                    {textMap[notificationType]}
                </Typography>
            </Col>
            {/* <Col span={5} style={{display: 'flex', justifyContent: 'center'}}>
                <Switch onChange={(checked, event) => {
                    changeSettings({
                        notification_type: notificationType,
                        enabled: checked,
                        communication_method: 'PUSH'
                    })
                }} checked={settingsMap.get(notificationType)?.get('PUSH')} />
            </Col> */}
            <Col span={15} style={{ display: 'flex', justifyContent: 'end' }}>
                <Switch onChange={(checked, event) => {
                    changeSettings({
                        notification_type: notificationType,
                        enabled: checked,
                        communication_method: 'SMS'
                    })
                }} checked={settingsMap.get(notificationType)?.get('SMS')} />
            </Col>
            {/* <Col span={5} style={{display: 'flex', justifyContent: 'center'}}>
                <Switch onChange={(checked, event) => {
                    changeSettings({
                        notification_type: notificationType,
                        enabled: checked,
                        communication_method: 'EMAIL'
                    })
                }} checked={settingsMap.get(notificationType)?.get('EMAIL')} />
            </Col> */}
        </Row>

    }, [notificationSettings])

    return (
        <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center'}}>
            <Col xs={24} sm={22} md={18} lg={12} style={{display: 'flex', flexDirection: 'column'}}>
                <Row>
                    <Col span={20} style={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
                        <Typography style={{ color: 'black', fontWeight: '600', fontSize: '18px' }}>Profile</Typography>
                    </Col>
                </Row>
                <Row style={{ marginTop: '20px', overflowY: 'auto' }}>
                    <Col span={24} style={{ width: '100%', overflowY: 'auto' }}>
                        <Card style={{ overflowY: 'auto', textAlign: 'left' }} title={'Contact Info'}>
                            <Row>
                                <Col span={3}>
                                    <Typography>
                                        <PhoneOutlined />
                                    </Typography>
                                </Col>
                                <Col span={21}>

                                    <Typography.Paragraph>
                                        {user.phone}
                                    </Typography.Paragraph>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={3}>
                                    <Typography>
                                        <MailOutlined />
                                    </Typography>
                                </Col>
                                <Col span={21}>

                                    <Typography.Paragraph
                                        editable={{
                                            tooltip: 'Email',
                                            onChange: setEmail,
                                            enterIcon: <CheckOutlined />,
                                        }}
                                    >
                                        {email || emailPlaceholder}
                                    </Typography.Paragraph>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </Row>
                <Row style={{ marginTop: '20px' }}>
                    <Col span={24} style={{ width: '100%', overflowY: 'auto' }}>
                        <Card style={{ overflowY: 'auto', textAlign: 'start' }} bodyStyle={{ paddingTop: 0 }} title={'Notifications'} loading={notificationLoading}>
                            <Row style={{ marginTop: '10px' }}>
                                <Col span={9}>
                                </Col>
                                {/* <Col span={5} style={{display: 'flex', justifyContent: 'center'}}>
                                    <MobileOutlined />
                                </Col> */}
                                <Col span={15} style={{ display: 'flex', justifyContent: 'end' }}>
                                    <MessageOutlined style={{ marginRight: '13px' }} />
                                </Col>
                                {/* <Col span={5} style={{display: 'flex', justifyContent: 'center'}}>
                                    <MailOutlined />
                                </Col> */}
                            </Row>
                            {buildRow('LOGIN')}
                            {buildRow('RECEIPT')}
                            {buildRow('DEALS')}
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col span={24} style={{ marginTop: '20px' }}>
                        <Button block onClick={openContactSupportModal}>
                            Contact Support
                        </Button>
                    </Col>
                </Row>
                <Row>
                    <Col span={24} style={{ marginTop: '20px' }}>
                        <Button block danger onClick={props.handleUserLogout}>
                            Sign Out
                        </Button>
                    </Col>
                </Row>
                <ContactSupportModal visible={contactSupportModalOpen} setVisible={setContactSupportModalOpen}
                    user={props.user} onError={props.onError} />
            </Col>
        </div >
    )
}
export default ProfileScreen 

import React, {useCallback, useEffect, useState} from 'react';
import {Card, Col, Row} from 'antd';
import {User} from '../models/User';
import {Mechanism} from '../models/MechanismTypes';
import {MechanismsService} from '../services/MechanismsService';
import VehicleList from '../mechanisms/VehicleList';


interface PendingVehiclesProps {
    user: User
    limit: number
    goToVehicle: any
    goToAllPendingVehicles: any
    onError: (description: string, title?: string) => void
}

const PendingVehicles: React.FC<PendingVehiclesProps> = (props) => {
    const [loading, setLoading] = useState<boolean>(true)
    const [pendingVehicles, setPendingVehicles] = useState<Mechanism[]>([])

    const getVehicles = useCallback(() => {
        MechanismsService.getPendingVehicles(0, props.limit, props.user.access_token, setLoading).subscribe((data: Mechanism[]) => {
            setPendingVehicles(data)
        }, (error: any) => {
            console.error(`Error fetching pending vehicles: ${error}`);
            props.onError(error);
        })

    }, [props.user])

    useEffect(() => {
        getVehicles()
    }, [props.user])
    const onAccept = useCallback((mechanism: Mechanism) => {
        mechanism.status = 'ACTIVE'
        MechanismsService.updateStatus(mechanism, props.user.access_token, setLoading).subscribe((data: any) => {
            getVehicles()
        }, (error: any) => {
            console.error(`Error activating vehicle: ${error}`);
            props.onError(error, 'Error Activating Vehicle')
        })
    }, [props.user])
    const onDecline = useCallback((mechanism: Mechanism) => {
        mechanism.status = 'DELETED';
        MechanismsService.deleteVehicle(mechanism, props.user.access_token, setLoading).subscribe((data: any) => {
            getVehicles()
        }, (error: any) => {
            console.error(`Error removing vehicle: ${error}`);
            props.onError(error, 'Error Removing Vehicle')

        })
    }, [props.user.access_token])
    if (pendingVehicles.length == 0) {
        return null
    }
    return <Card title={'Pending Vehicles'} headStyle={{ textAlign: 'start' }} style={{ overflowY: 'auto', marginTop: '10px' }} bodyStyle={{}} loading={loading}
        extra={<a onClick={props.goToAllPendingVehicles}>View All</a>}>
        <Row>
            <Col span={24} style={{ width: '100%', overflowY: 'auto', textAlign: 'left' }}>
                <VehicleList user={props.user} vehicleList={pendingVehicles} enrolledVehicleList={[]} onClick={props.goToVehicle} onAccept={onAccept} onDecline={onDecline} />
            </Col>
        </Row>
    </Card>
};

export default PendingVehicles;
import React, {type FC, useEffect, useState} from 'react'
import {Button, Card, Col, Row, Typography} from 'antd'
import {User} from '../models/User'
import {UserService} from '../services/UserService'
import {formatCurrency} from '../utils/NumberUtils'
import {Credit} from '../models/Credit'
import {UserOutlined} from '@ant-design/icons'
import {useNavigate} from 'react-router-dom'
import LoyaltyPreview from './LoyaltyPreview'
import PromosPreview from "./PromosPreview";

interface RewardsScreenProps {
    user: User
    onError: (description: string, title?: string) => void
}

const RewardsScreen: FC<RewardsScreenProps> = (props) => {
    const [credits, setCredits] = useState<number>(0)
    const [creditAmount, setCreditAmount] = useState<number>(0)
    const [creditsLoading, setCreditsLoading] = useState<boolean>(true)
    const [promos, setPromos] = useState<any>([])
    const navigate = useNavigate()
    const goToProfile = () => {
        navigate('/profile')
    }
    useEffect(() => {
        const initialLoad = creditsLoading
        UserService.getCredits(props.user.access_token, initialLoad ? setCreditsLoading : null).subscribe(
            (data: Credit[]) => {
                let totalCredits = 0
                let creditCount = 0
                data?.forEach(credit => {
                    if (credit.amount_consumed < credit.amount) {
                        totalCredits += (credit.amount - credit.amount_consumed)
                        creditCount += 1
                    }
                });
                setCreditAmount(totalCredits)
                setCredits(creditCount)
            },
            (error: any) => {
                console.error(`Error getting credits ${error}`)
                props.onError(error)
            }
        )
    }, [props.user])


    let creditVerbiage = <Typography>Refer friends to earn credits</Typography>
    if (credits > 0) {
        creditVerbiage = <Typography>You have {credits} credit{credits > 1 && 's'} worth <span style={{ color: '#7ac968', fontWeight: 'bold' }}>{formatCurrency(creditAmount)}</span>.</Typography>
    }

    return (
        <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center'}}>
            <Col xs={24} sm={22} md={18} lg={12} style={{display: 'flex', flexDirection: 'column'}}>
                <Row>
                    <Col span={20} style={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
                        <Typography style={{ color: 'black', fontWeight: '600', fontSize: '18px' }}>Rewards</Typography>
                    </Col>
                    <Col span={4} style={{ display: 'flex', flexDirection: 'column', alignItems: 'end' }}>
                        <Button icon={<UserOutlined />} shape='circle' onClick={goToProfile}></Button>
                    </Col>
                </Row>
                <Row style={{ marginTop: '20px', display: promos.length > 0 ? 'block' : 'none'}}>
                    <Col span={24} style={{ width: '100%', overflowY: 'auto' }}>
                        <PromosPreview user={props.user} onError={props.onError} promos={promos} setPromos={setPromos}/>
                    </Col>
                </Row>
                <Row style={{ marginTop: '20px' }}>
                    <Col span={24} style={{ width: '100%', overflowY: 'auto' }}>
                        <LoyaltyPreview user={props.user} onError={props.onError} />
                    </Col>
                </Row>
                <Row style={{ marginTop: '20px' }}>
                    <Col span={24} style={{ width: '100%', overflowY: 'auto' }}>
                        <Card style={{ overflowY: 'auto', textAlign: 'left' }} title={'Credits'} loading={creditsLoading}>
                            {creditVerbiage}
                        </Card>
                    </Col>
                </Row>
            </Col>
        </div >
    )
}
export default RewardsScreen 

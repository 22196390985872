import React, {type FC, useEffect, useState} from 'react'
import type {MenuProps} from 'antd'
import {Button, Card, Col, Dropdown, Input, Popconfirm, Row, Space, Typography} from 'antd'
import {MoreOutlined, QuestionCircleOutlined} from '@ant-design/icons'
import {useParams} from 'react-router'
import {useNavigate} from 'react-router-dom'
import {formatDateTime} from '../utils/DateUtils'
import ActivitySummaryComponent from '../home/ActivitySummaryComponent'
import {PaymentMethodsService} from '../services/PaymentMethodsService'
import {User} from '../models/User'
import {PaymentMethod} from '../models/PaymentMethodTypes'
import {MechanismsService} from "../services/MechanismsService";
import {Mechanism} from "../models/MechanismTypes";

interface PaymentMethodDetailsProps {
  user: User
  onError: (description: string, title?: string) => void
}

const PaymentMethodDetails: FC<PaymentMethodDetailsProps> = (props) => {
  const navigate = useNavigate()
  const { id } = useParams()
  const [paymentMethodInfo, setPaymentMethodInfo] = useState<PaymentMethod>()
  const [originalMechanism, setOriginalMechanism] = useState<Mechanism>();
  const [loading, setLoading] = useState<boolean>(true);
  const [isEditingNickname, setIsEditingNickname] = useState<boolean>(false);
  const [nicknameInput, setNicknameInput] = useState<string>(paymentMethodInfo?.nickname || '');

  useEffect(() => {
    // TODO don't hardcode bounds
    PaymentMethodsService.getCardById(id, props.user.access_token, setLoading).subscribe((data: PaymentMethod) => {
      setPaymentMethodInfo(data)
    }, (error: any) => {
      console.error(`Error fetching transactions: ${error}`);
      props.onError(error, `Error Fetching Transactions`);
    })
  }, [props.user])

  useEffect(() => {
    if (paymentMethodInfo?.mechanism_id) {
      MechanismsService.getById(paymentMethodInfo.mechanism_id, props.user.access_token, setLoading).subscribe((data: Mechanism) => {
        setOriginalMechanism(data)
      }, (error: any) => {
        console.error(`Error fetching original mechanism: ${error}`);
        props.onError(error, `Error Fetching Original Mechanism Info`);
      })
    }
  }, [paymentMethodInfo])

  const items: MenuProps['items'] = [
    {
      key: '1',
      label: (
        <a onClick={() => { editNickname() }} rel={'noopener noreferrer'} >
          Edit Nickname
        </a>
      ),
    },
    {
      key: '2',
      label: (
        <a onClick={() => { changeStatus() }} rel={'noopener noreferrer'} >
          {paymentMethodInfo?.status == 'DISABLED' ? 'Activate' : 'Disable'}
        </a>
      ),
    },
    {
      key: '3',
      label: (
        <Popconfirm
          title="Remove Payment Method"
          description="Are you sure you want to remove this payment method?"
          icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
          placement={'bottom'}
          onConfirm={() => { remove() }}
          okText={'Remove'}
        >
          <a rel={'noopener noreferrer'} >
            Remove
          </a>
        </Popconfirm>
      ),
    },
    {
      type: 'divider'
    },
    {
      key: '4',
      label: 'Set Spending Limit',
      disabled: true
    }
  ]

  const editNickname = () => {
    setNicknameInput(paymentMethodInfo?.nickname || '');
    setIsEditingNickname(true);
  }

  const editNicknameComplete = () => {
    paymentMethodInfo!.nickname = nicknameInput;
    PaymentMethodsService.renameCard(JSON.stringify(paymentMethodInfo), props.user.access_token, setLoading).subscribe((data: any) => {
      setPaymentMethodInfo(data);
      setIsEditingNickname(false);
    }, (error: any) => {
      console.error(`Error updating card nickname: ${error}`);
      props.onError(error, `Error Updating Card`);
      setIsEditingNickname(false);
    });
  }

  const changeStatus = () => {
    let toStatus: string = paymentMethodInfo?.status == 'DISABLED' ? 'ACTIVE' : 'DISABLED';
    paymentMethodInfo!.status = toStatus;
    PaymentMethodsService.updateCardStatus(JSON.stringify(paymentMethodInfo), props.user.access_token, setLoading).subscribe((data: any) => {
      window.heap.track('Card Status Update Success', {paymentMethodId: paymentMethodInfo?.id, status: toStatus})
      setPaymentMethodInfo(data)
    }, (error: any) => {
      console.error(`Error updating card status: ${error}`);
      window.heap.track('Card Status Update Error', {paymentMethodId: paymentMethodInfo?.id, status: toStatus, error: error})
      props.onError(error, `Error Updating Card`);
    });
  }

  const remove = () => {
    paymentMethodInfo!.status = 'DELETED';
    PaymentMethodsService.deleteCard(JSON.stringify(paymentMethodInfo), props.user.access_token, setLoading).subscribe((data: any) => {
      window.heap.track('Card Remove Success', {paymentMethodId: paymentMethodInfo?.id})
      setPaymentMethodInfo(data)
      navigate('/cards')
    }, (error: any) => {
      window.heap.track('Card Remove Error', {paymentMethodId: paymentMethodInfo?.id, error: error})
      console.error(`Error deleting card: ${error}`);
      props.onError(error, `Error Deleting Card`);
    });
  }

  const goToOriginalMechanism = () => {
    if (originalMechanism?.id) {
      navigate(`/identifiers/vehicles/${originalMechanism.meta.id}`)
    }
  }

  return (

      <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center'}}>
        <Col xs={24} sm={22} md={18} lg={12} style={{display: 'flex', flexDirection: 'column'}}>
          <Row>
            <Col span={20} style={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
              {isEditingNickname ? (
                <Space.Compact>
                  <Input
                    value={nicknameInput}
                    onChange={(e) => setNicknameInput(e.target.value)}
                  />
                  <Button type={'primary'} onClick={() => editNicknameComplete()}>Done</Button>
                </Space.Compact>
              ) : (
                <Typography style={{ color: 'black', fontWeight: '600', fontSize: '18px' }}>
                  {paymentMethodInfo?.nickname}
                </Typography>
              )}
            </Col>
            <Col span={4} style={{ display: 'flex', flexDirection: 'column', alignItems: 'end' }}>

              <Dropdown menu={{ items }} >
                <MoreOutlined style={{ fontSize: 20 }} />
              </Dropdown>

            </Col>
          </Row>
          <Row style={{ marginTop: '20px' }}>
            <Card style={{ textAlign: 'left', width: '100%' }} title={'Card Details'} loading={loading}>
              <Row>
                <Typography style={{ color: 'black' }}>
                  {paymentMethodInfo?.meta.card.brand.toUpperCase()} ending in {paymentMethodInfo?.meta.card.last4}
                </Typography>
              </Row>
              <Row>
                <Typography style={{ color: 'black' }}>
                  <b>Status:</b> {paymentMethodInfo?.status}
                </Typography>
              </Row>
              <Row>
                <Typography style={{ color: 'black' }}>
                  <b>Added:</b> {paymentMethodInfo?.date_created ? formatDateTime(paymentMethodInfo?.date_created) : 'N/A'}
                </Typography>
              </Row>
    {/*          <Row>
                <Typography style={{ color: 'black' }}>
                  <b>Last Used:</b> {paymentMethodInfo?.date_last_used ? formatDateTime(paymentMethodInfo?.date_last_used) : 'N/A'}
                </Typography>
              </Row>*/}
              <Row>
                {paymentMethodInfo?.account_accessible &&
                    <Typography style={{ color: 'black' }}>
                      <b>Availability:</b> Any Mechanism
                    </Typography>
                }
                {!paymentMethodInfo?.account_accessible &&
                    <Typography style={{ color: 'black' }}>
                      <b>Availability:</b> Only&nbsp;
                      <a onClick={() => { goToOriginalMechanism() }} rel={'noopener noreferrer'} >
                        {originalMechanism?.nickname}
                      </a>
                    </Typography>
                }
              </Row>
            </Card>
          </Row>
          <Row style={{ flex: 1, overflow: 'auto', marginTop: '20px' }}>
            <Col span={24} style={{ width: '100%', height: '100%' }}>
              <ActivitySummaryComponent onError={props.onError} user={props.user} payment_method={paymentMethodInfo} />
            </Col>
          </Row>
        </Col>
    </div>
  )
}
export default PaymentMethodDetails
